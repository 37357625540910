import * as React from 'react';
import { AuctionStatus } from '@/types/LiveAuctionState';
import { escapeQuotes } from '@/utils/escapeQuotes';
import {
    getCatalogAddress,
    getCatalogCoverLots,
    getCatalogSellerId,
    getCatalogStartTime,
    getCatalogStatus,
    getCatalogTitle,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getCatalogUrl, getMediumItemImageUrl, mobileLogo } from '@/utils/urls';
import { getSeller } from '@/redux/modules/seller';
import { Helmet } from 'react-helmet-async';
import { safelyEscapeText } from '@/utils/safelyRender';
import { shortFormatDate } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import isEqual from 'react-fast-compare';

type Props = {
    baseUrl: string;
    catalogId: number;
};

const CatalogMeta = ({ baseUrl, catalogId }: Props) => {
    const { formatMessage } = useIntl();

    const address = useAppSelector((state) => getCatalogAddress(state, catalogId));
    const coverLots = useAppSelector((state) => getCatalogCoverLots(state, catalogId));
    const sellerId = useAppSelector((state) => getCatalogSellerId(state, catalogId));
    const saleStartTs = useAppSelector((state) => getCatalogStartTime(state, catalogId));
    const catalogStatus = useAppSelector((state) => getCatalogStatus(state, catalogId));
    const catalogTitle = useAppSelector((state) => getCatalogTitle(state, catalogId));

    const {
        address: sellerAddress,
        address2: sellerAddress2,
        city: sellerCity,
        country: sellerCountry,
        name: sellerName,
        stateCode: sellerStateCode,
        zip,
    } = useAppSelector((state) => getSeller(state, sellerId));

    if (!catalogId || !catalogTitle) {
        return null;
    }

    const pastAuction = catalogStatus === AuctionStatus.Done;

    const { address1, address2, city, country, lat, lng, postalCode, state: stateCode } = address;

    const displayAddress = address2 ? `${address1} ${address2}` : `${address1}`;
    const safeDisplayAddress = safelyEscapeText(displayAddress);

    const location = [city, stateCode, country].filter((element) => element).join(', ');
    const sellerDisplayAddress = sellerAddress2 ? `${sellerAddress} ${sellerAddress2}` : `${sellerAddress}`;
    const safeSellerDisplayAddress = safelyEscapeText(sellerDisplayAddress);

    const description =
        `${formatMessage({ id: 'auctioneer' })}: ${sellerName}, ` +
        `${catalogTitle}, ` +
        `${formatMessage({ id: 'date' })}: ${shortFormatDate(saleStartTs)}, ` +
        `${formatMessage({ id: 'location' })}: ${location}`;

    // only escape a quote if it hasn't already been escaped
    const safeDescription = safelyEscapeText(escapeQuotes(description));

    const imageItemId = coverLots?.[0]?.itemId || 0;
    const imageImageVersion = coverLots?.[0]?.imageVersion || 1;

    let imageUrl = getMediumItemImageUrl(sellerId, catalogId, imageItemId, 1, imageImageVersion) || mobileLogo;
    const safeCatalogTitle = safelyEscapeText(escapeQuotes(catalogTitle));

    const catalogPath = getCatalogUrl(catalogId, catalogTitle);

    let locationStr = '';
    if (lat && lng) {
        locationStr = `,
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": ${lat},
                "longitude": ${lng}
            }`;
    }
    const ts = saleStartTs * 1000;
    const startDate = new Date(ts);
    const endDate = new Date(ts + 14400000);
    const url = `${baseUrl}${catalogPath}`;
    const availability = !pastAuction ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';
    return (
        <Helmet>
            <script type="application/ld+json">
                {`{
                        "@context": "https://schema.org",
                        "@type": "SaleEvent",
                        "description": "${safeDescription}",
                        "name": "${safeCatalogTitle}",
                        "image": "${imageUrl}",
                        "startDate": "${startDate.toISOString()}",
                        "endDate": "${endDate.toISOString()}",
                        "url": "${url}",
                        "location": {
                            "@type": "Place",
                            "name": "${sellerName}",
                            "address": {
                                "@type": "PostalAddress",
                                "addressCountry": "${country}",
                                "addressLocality": "${city}",
                                "addressRegion": "${stateCode}",
                                "streetAddress": "${escapeQuotes(safeDisplayAddress)}",
                                "postalCode": "${postalCode}"
                            }${locationStr}
                        },
                        "offers": {
                            "availability": "${availability}",
                            "price": 0,
                            "priceCurrency": "USD",
                            "validFrom": "${startDate.toISOString()}",
                            "url": "${url}"
                        },
                        "organizer": {
                            "@type": "organization",
                            "address": {
                                "@type": "PostalAddress",
                                "addressCountry": "${sellerCountry}",
                                "addressLocality": "${sellerCity}",
                                "addressRegion": "${sellerStateCode}",
                                "streetAddress": "${escapeQuotes(safeSellerDisplayAddress)}",
                                "postalCode": "${zip}"
                              },
                            "name": "${sellerName}"
                        },
                        "performer": {
                            "@type": "Organization",
                            "name": "${sellerName}"
                        }
                    }`}
            </script>
        </Helmet>
    );
};

export default React.memo<Props>(CatalogMeta, isEqual);
