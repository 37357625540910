import { catalogSortOrders } from '@/enums';
import { changePaginationPageSize, getPaginationPageSize, getPaginationSortOrder } from '@/redux/modules/pagination';
import { getBaseUrl } from '@/redux/modules/config';
import { getSellerCatalogDoneCount } from '@/redux/modules/sellerCatalogCounts';
import { getSellerCatalogs } from '@/redux/modules/filteredItems';
import { getSellerName } from '@/redux/modules/seller';
import { getSellerViewData } from '@/redux/modules/viewData';
import { PAGE_ITEMS_TO_VIEW } from '@/components/Pagination/helpers/filterSortOptions';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import CatalogMeta from './CatalogMeta';
import SellerPagePastSection from './SellerPagePastSection';

const sortId = 'seller-catalogs-past';

type Props = {
    sellerId: number;
};

const SellerPagePastSectionContainer = ({ sellerId }: Props) => {
    const dispatch = useAppDispatch();

    const globalPageSize = useAppSelector(getPaginationPageSize);
    const pageSize = globalPageSize === 'all' ? 120 : globalPageSize;
    const baseUrl = useAppSelector(getBaseUrl);
    const sellerName = useAppSelector((state) => getSellerName(state, sellerId));

    const doneCount = useAppSelector((state) => getSellerCatalogDoneCount(state, sellerId));
    const sortFromState =
        useAppSelector((state) => getPaginationSortOrder(state, sortId)) || catalogSortOrders.DATE_DESCENDING;
    const { page: pageFromState, searchTerms: termsFromState } = useAppSelector((state) =>
        getSellerViewData(state, sellerId, 'past')
    );

    const { catalogIds, filteredCount } = useAppSelector((state) =>
        getSellerCatalogs(state, sellerId, {
            count: pageSize,
            page: pageFromState,
            sort: sortFromState,
            tab: 'ended',
            terms: termsFromState,
        })
    );

    // ensure that if we havent loaded all the items yet and have no filter, that we show the filteredCount as the doneCount
    const actualFilteredCount = !termsFromState ? doneCount : filteredCount;
    return (
        <>
            {catalogIds.map((catalogId) => {
                return (
                    <CatalogMeta
                        baseUrl={baseUrl}
                        catalogId={catalogId}
                        key={catalogId}
                    />
                );
            })}
            <SellerPagePastSection
                catalogIds={catalogIds}
                changePaginationPageSize={(p) => dispatch(changePaginationPageSize(p))}
                filteredCount={actualFilteredCount}
                page={pageFromState}
                pageSize={pageSize}
                searchTerms={termsFromState}
                sellerName={sellerName}
                sortOptions={catalogSortOrders}
                sortOrder={sortFromState}
                totalItemsCount={doneCount}
                viewsToShow={PAGE_ITEMS_TO_VIEW}
            />
        </>
    );
};

export default SellerPagePastSectionContainer;
