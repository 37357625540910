import * as React from 'react';
import { getAuctioneerDirectoryUrl } from '@/utils/urls';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import BreadcrumbWrapper from '@/components/Breadcrumbs/BreadcrumbWrapper';

type Props = {
    sellerName: string;
};

const SellerPageBreadcrumbs = ({ sellerName }: Props) => {
    const { formatMessage } = useIntl();

    const crumbs = React.useMemo(() => {
        return [
            {
                label: formatMessage({ id: 'auction_house_directory' }),
                link: getAuctioneerDirectoryUrl(),
            },
            { label: sellerName },
        ];
    }, [formatMessage, sellerName]);

    return <BreadcrumbWrapper crumbs={crumbs} />;
};

export default React.memo<Props>(SellerPageBreadcrumbs);
