import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import {
    CompleteAccountModalContext,
    ModalContext,
    openSendSellerConsignmentMessageModal,
    openSendSellerMessageModal,
} from '@/redux/modules/modal';
import { EventContext } from '@/redux/modules/analytics';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getGoogleApiKey } from '@/redux/modules/config';
import { getSellerAddress, getSellerName } from '@/redux/modules/seller';
import { getSellerPhoneNumber } from '@/redux/modules/sellerDetail';
import { Link } from '@liveauctioneers/hammer-ui-core/link';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import Address from '@liveauctioneers/caterwaul-components/lib/Address/Address';
import GoogleMapSmall from '@/components/GoogleMap/GoogleMapSmall';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';
import styled from 'styled-components';

const CHRISTIES_SELLER_ID = 6670;

type Props = {
    sellerId: number;
};

const SellerMap = ({ sellerId }: Props) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const googleApiKey = useAppSelector(getGoogleApiKey);
    const { address, address2, city, countryCode, stateCode, zip } = useAppSelector((state) =>
        getSellerAddress(state, sellerId)
    );
    const name = useAppSelector((state) => getSellerName(state, sellerId));
    const phoneNumber = useAppSelector((state) => getSellerPhoneNumber(state, sellerId));

    const modalContext = React.useMemo<ModalContext>(
        () => ({
            eventContext: EventContext.sendMessage,
            houseId: sellerId,
            id: sellerId,
            type: CompleteAccountModalContext.AskQuestionAuctioneer,
        }),
        [sellerId]
    );

    const showSendSellerMessageModal = () => {
        const subject = formatMessage({ id: 'general_question' });
        dispatch(openSendSellerMessageModal({}, sellerId, name, subject, ''));
    };

    const showSendSellerConsignmentMessageModal = () => {
        dispatch(openSendSellerConsignmentMessageModal({}, sellerId, name));
    };

    return (
        <StyledSellerMap>
            <GoogleMapSmall
                googleApiKey={googleApiKey}
                sellerId={sellerId}
            />
            <StyledMapInfo>
                <Address
                    address={{
                        address1: address,
                        address2,
                        city,
                        countryCode,
                        postalCode: zip,
                        state: stateCode,
                    }}
                />
                {Boolean(phoneNumber) && <StyledPhoneNumber>{phoneNumber}</StyledPhoneNumber>}
                <ShouldLogInContainer
                    modalContext={modalContext}
                    requireCompleteProfile
                    requireLogin
                    runFunction={showSendSellerMessageModal}
                >
                    <Link
                        data-testid="sellerSendMessageLink"
                        size="sm"
                    >
                        <FormattedMessage id="send_message" />
                    </Link>
                </ShouldLogInContainer>
                {/** Christie's (6670) doesnt want people seeing the consign link */}
                {sellerId !== CHRISTIES_SELLER_ID && (
                    <ShouldLogInContainer
                        modalContext={{
                            eventContext: EventContext.consign,
                            id: sellerId,
                            sellerId,
                            type: CompleteAccountModalContext.Default,
                        }}
                        requireCompleteProfile
                        requireLogin
                        runFunction={showSendSellerConsignmentMessageModal}
                    >
                        <Link
                            data-testid="sellerConsignItemLink"
                            size="sm"
                        >
                            <FormattedMessage id="consign_item" />
                        </Link>
                    </ShouldLogInContainer>
                )}
            </StyledMapInfo>
        </StyledSellerMap>
    );
};

export default SellerMap;

const StyledSellerMap = styled.div`
    padding: 13px 14px 14px;
    display: flex;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    box-sizing: border-box;
    width: 524px;

    @media (max-width: 1200px) {
        padding: 8px;
        flex-direction: column;
        width: 212px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        padding: 0;
        flex-direction: column;
        border: none;
        width: 100%;
        text-align: center;
    }
`;

const StyledMapInfo = styled.div`
    padding: 0 17px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1200px) {
        padding: 6px 4px 0;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        padding: 0 4px;
        margin: 10px 0;
        text-align: center;
    }
`;

const StyledPhoneNumber = styled(BodySecondary)`
    height: 16px;
    margin-bottom: 10px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        width: 100%;
        text-align: center;
    }
`;
