import { addImageParams, getAuctioneerLogoUrl, imagePlaceholder } from '@/utils/urls';
import CompleteAccountBanner from '@/components/CompleteAccountBanner/CompleteAccountBanner';
import debounce from 'lodash/debounce';
import RatingsContainer from '@/pages/Ratings/RatingsContainer';
import React, { Component } from 'react';
import scrollIntoView from 'scroll-into-view';
import SellerComponent from './SellerComponent';
import SellerPageBreadcrumbs from './SellerPageBreadcrumbs';
import SellerPageHeaderMeta from './SellerPageHeaderMeta';
import SellerPagePastSectionContainer from './SellerPagePastSectionContainer';
import SellerPageRecordResultsSection from './SellerPageRecordResultsSection';
import SellerPageTabLinks from './SellerPageTabLinks';
import SellerPageUpcomingSectionContainer from './SellerPageUpcomingSectionContainer';
import StickyHeader from '@/components/StickyHeader/StickyHeader';
import styled, { keyframes } from 'styled-components';

type Props = {
    baseUrl: string;
    hasRecordResults: boolean;
    queryParams: any;
    sellerId: number;
    sellerLogoId: string;
    sellerName: string;
    sellerRating: any;
    whiteLabel: boolean;
};

type State = {
    activeSection: string;
    showStickyNav: boolean;
};

export default class SellerPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onScroll = debounce(this.onScroll, 10);
    }

    state = {
        activeSection: 'upcoming',
        showStickyNav: false,
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.queryParams.page !== this.props.queryParams.page) {
            this.scrollToPastAuctions();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    scrollToPastAuctions = () => {
        const pastSection = document.getElementById('seller-page-past-section');
        scrollIntoView(pastSection);
    };

    scrollToReviews = () => {
        this.scrollToSection('seller-page-reviews-section');
    };

    onScroll = () => {
        const heights = this.getHeights();
        let activeSection = 'upcoming';
        if (heights?.results < 90) {
            activeSection = 'results';
        } else if (heights?.past < 90) {
            activeSection = 'past';
        } else if (heights?.reviews < 90) {
            activeSection = 'reviews';
        }
        if (this.state.activeSection !== activeSection) {
            this.setState({ activeSection });
        }

        if (heights?.links < -40) {
            if (!this.state.showStickyNav) {
                this.setState({ showStickyNav: true });
            }
        } else {
            if (this.state.showStickyNav) {
                this.setState({ showStickyNav: false });
            }
        }
    };

    scrollToSection = (id: string) => {
        const scrollTarget = document.getElementById(`${id}`);
        if (Boolean(scrollTarget)) {
            scrollIntoView(scrollTarget, {
                align: {
                    top: 0,
                },
            });
        }
    };

    scrollToTop = () => {
        window.scrollTo({
            behavior: 'smooth',
            top: 0,
        });
    };

    getHeights = () => {
        if (Boolean(document)) {
            const linksElement = document.getElementById('tabLinks');
            const pastElement = document.getElementById('seller-page-past-header');
            const newsElement = document.getElementById('seller-page-news-section');
            const resultsElement = document.getElementById('seller-page-results-section');
            const reviewsElement = document.getElementById('seller-page-reviews-section');
            const upcomingElement = document.getElementById('seller-page-upcoming-section');
            const linksRect: any = linksElement ? linksElement.getBoundingClientRect() : {};
            const pastRect: any = pastElement ? pastElement.getBoundingClientRect() : {};
            const newsRect: any = newsElement ? newsElement.getBoundingClientRect() : {};
            const resultsRect: any = resultsElement ? resultsElement.getBoundingClientRect() : {};
            const reviewsRect: any = reviewsElement ? reviewsElement.getBoundingClientRect() : {};
            const upcomingRect: any = upcomingElement ? upcomingElement.getBoundingClientRect() : {};
            return {
                links: linksRect?.top,
                news: newsRect?.top,
                past: pastRect?.top,
                results: resultsRect?.top,
                reviews: reviewsRect?.top,
                upcoming: upcomingRect?.top,
            };
        }
        return {};
    };

    render() {
        const { baseUrl, hasRecordResults, queryParams, sellerId, sellerLogoId, sellerName, sellerRating, whiteLabel } =
            this.props;

        const { showStickyNav } = this.state;

        if (!sellerName) {
            return null;
        }

        const hideReviews = !sellerRating.allowPublicReviews;
        const logo = Boolean(sellerLogoId) ? getAuctioneerLogoUrl(sellerLogoId, 80) : imagePlaceholder;
        const src = addImageParams({ height: 80, url: logo });

        return (
            <StyledSellerPage>
                <SellerPageHeaderMeta sellerId={sellerId} />
                <StyledCompleteAccountBanner />
                {!whiteLabel && <SellerPageBreadcrumbs sellerName={sellerName} />}
                <SellerComponent
                    scrollToReviews={this.scrollToReviews}
                    sellerId={sellerId}
                    showConsignmentModal={Boolean((queryParams || {}).consign)}
                />
                <SellerPageTabLinks
                    activeTab={this.state.activeSection}
                    hasRecordResults={hasRecordResults}
                    hideReviews={hideReviews}
                    id="tabLinks"
                    nonSticky
                    scrollToSection={this.scrollToSection}
                />
                {showStickyNav && (
                    <StyledStickyHeaderSlideIn>
                        <StyledSellerNavigation onClick={this.scrollToTop}>
                            <StyledImageContainer>
                                <StyledImageLogo
                                    alt={sellerName}
                                    src={src}
                                />
                            </StyledImageContainer>
                        </StyledSellerNavigation>
                        <SellerPageTabLinks
                            activeTab={this.state.activeSection}
                            hasRecordResults={hasRecordResults}
                            hideReviews={hideReviews}
                            scrollToSection={this.scrollToSection}
                        />
                    </StyledStickyHeaderSlideIn>
                )}
                <SellerPageUpcomingSectionContainer
                    baseUrl={baseUrl}
                    sellerId={sellerId}
                    sellerName={sellerName}
                />
                {!hideReviews && (
                    <div id="seller-page-reviews-section">
                        <RatingsContainer sellerId={sellerId} />
                    </div>
                )}
                <SellerPagePastSectionContainer sellerId={sellerId} />
                <SellerPageRecordResultsSection sellerId={sellerId} />
            </StyledSellerPage>
        );
    }
}

const StyledSellerPage = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledCompleteAccountBanner = styled(CompleteAccountBanner)`
    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin: 16px auto 0;
        width: calc(100% - 32px);
    }
`;

const StyledSellerNavigation = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;

const StyledImageContainer = styled.div`
    min-width: 40px;
    height: 40px;
    width: 40px;
    margin-right: 16px;
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledImageLogo = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const slide = keyframes`
    0% {
        transform: translateY(-60px);
    }

    100% {
        transform: translateY(0);
    }
`;

const StyledStickyHeaderSlideIn = styled(StickyHeader)`
    animation: ${slide} 0.5s ease;
    overflow: hidden;
`;
