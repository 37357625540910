import * as React from 'react';
import { Checkbox } from '@liveauctioneers/hammer-ui-core/checkbox';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import {
    filterSortOptions,
    getMyBidsSortOptionLabelText,
    PAGE_ITEMS_TO_VIEW_PLUS_ALL,
} from './helpers/filterSortOptions';
import { HistoryMethod, ModifyQueryParamsAction, useModifyQueryParams } from '@/hooks/queryParams/useModifyQueryParams';
import { preventDefault } from '@/utils/preventDefault';
import { SearchInput } from '@liveauctioneers/hammer-ui-core/searchInput';
import { Select } from '@liveauctioneers/hammer-ui-core/select';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { UserBidPlacedItemsSortEnum, UserBidPlacedItemsStatusEnum } from '@/types/item/UserBidPlacedItem';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import NoResults from './NoResults';
import Pagination from './hammer-pagination/Pagination';
import SearchStatus from './SearchStatus';

type Props = {
    buyNow?: boolean;
    buyNowFilterAvailable?: boolean;
    changePaginationPageSize: Function;
    children: React.ReactNode;
    className?: string;
    empty?: boolean;
    emptyMessage?: React.ReactNode;
    filter?: string;
    filterOptions?: any;
    filteredItemCount: number;
    isMyBids?: boolean;
    page: number;
    pageSize: number | 'all';
    searchTerms: string;
    showFilter?: boolean;
    sortOptions: any;
    sortOrder: string;
    status?: UserBidPlacedItemsStatusEnum;
    throbberMessage?: React.ReactNode;
    totalItemsCount: number;
    type: string;
    viewsToShow?: any[];
};

const PaginateSortFilter = ({
    buyNow = false,
    buyNowFilterAvailable = false,
    changePaginationPageSize,
    children,
    className = '',
    empty = false,
    emptyMessage,
    filter = 'won',
    filteredItemCount,
    filterOptions = [],
    isMyBids = false,
    page = 1,
    pageSize,
    searchTerms = '',
    showFilter = false,
    sortOptions = [],
    sortOrder,
    status,
    totalItemsCount = 0,
    type = 'lots',
    viewsToShow = PAGE_ITEMS_TO_VIEW_PLUS_ALL,
}: Props) => {
    const { formatMessage, formatNumber } = useIntl();
    const modifyQueryParams = useModifyQueryParams();

    const setSearch = React.useCallback(
        (value: string) => {
            if (value) {
                const search = value.toLowerCase().trim();
                modifyQueryParams({
                    action: ModifyQueryParamsAction.ADD,
                    method: HistoryMethod.REPLACE,
                    query: { terms: search },
                });
            } else {
                modifyQueryParams({
                    action: ModifyQueryParamsAction.REMOVE,
                    params: 'terms',
                });
            }
        },
        [modifyQueryParams]
    );

    React.useEffect(() => {
        debounce(setSearch, 300);
    }, [setSearch]);

    const handlePageSizeChange = ({ value }: { value: number }) => {
        changePageSize(value);
    };

    const handleSearchChange = (search: string) => {
        setSearch(search);
    };

    const handleSortChange = ({ value }: { value: string }) => {
        modifyQueryParams({
            action: ModifyQueryParamsAction.ADD,
            method: HistoryMethod.REPLACE,
            query: { sort: value },
        });
    };

    const handleBuyNowChecked = (event: any) => {
        const buyNow = event.currentTarget.checked;
        if (buyNow) {
            modifyQueryParams({
                action: ModifyQueryParamsAction.ADD,
                method: HistoryMethod.REPLACE,
                query: { buyNow },
            });
        } else {
            modifyQueryParams({
                action: ModifyQueryParamsAction.REMOVE,
                params: 'buyNow',
            });
        }
    };

    const handleFilterChange = ({ value }: { value: string }) => {
        if (filter !== value) {
            modifyQueryParams({
                action: ModifyQueryParamsAction.ADD,
                query: { filter: value },
            });
        }
    };

    const changePageSize = (pageSize: number) => {
        // it's necessary to manually set the page size before navigating
        // because we need to read it off the cookie, not the params.
        // at some point this might enjoy a refactor, consolidating this
        // with the pattern used in the search page.
        changePaginationPageSize(pageSize);
        modifyQueryParams({
            action: ModifyQueryParamsAction.ADD,
            query: { pageSize },
        });
    };

    const mapFilterOptions = (option: string) => {
        if (!option) {
            return {};
        }

        /**
         * The MyBidsPage intl strings are better organized in the intl file (and strongly typed by UserBidPlacedItemsBidStatusEnum)
         *
         * If we are on the My Bids page, then use the new intl strings
         * If we are on a different page (MyAuctionsPage, SellerPagePastSection, StorefrontPage etc), use the "old" intl strings
         */
        if (isMyBids) {
            return {
                label: formatMessage({
                    id: `paginateSortFilter.myBidsPagination.filterOptions.${filterOptions[option]}`,
                }),
                value: filterOptions[option],
            };
        } else {
            return {
                label: formatMessage({ id: option.toLowerCase() }),
                value: option,
            };
        }
    };

    const viewOptions = viewsToShow.map((option: string) => {
        const label = option === 'all' ? formatMessage({ id: option }) : option;
        return { label, value: option };
    });

    const sortByOptions = filterSortOptions(Object.keys(sortOptions), isMyBids);
    const filteredSortOptions = sortByOptions.map((option) => {
        if (isMyBids) {
            return {
                label: formatMessage({
                    id: getMyBidsSortOptionLabelText(option as keyof typeof UserBidPlacedItemsSortEnum, status),
                }),
                value: sortOptions[option],
            };
        }

        return {
            label: formatMessage({ id: option.toLowerCase() }),
            value: option,
        };
    });

    const mappedFilterOptions = filterOptions ? Object.keys(filterOptions).map(mapFilterOptions) : [];
    const placeholderMessage = `Search ${formatNumber(filteredItemCount)} ${type}`;

    return (
        <div className={cn('mb-lg', className)}>
            <SearchStatus
                clear={() => {
                    setSearch('');
                }}
                itemCount={filteredItemCount}
                search={searchTerms}
            />
            {empty ? (
                <NoResults>{emptyMessage}</NoResults>
            ) : (
                <Pagination
                    filter={
                        <div
                            className="flex w-full justify-between gap-md border-b border-border-secondary pb-lg smMax:flex-col smMax:gap-sm"
                            data-testid="filterControls"
                            onSubmit={preventDefault}
                        >
                            <SearchInput
                                aria-label={formatMessage({
                                    id: 'ariaLabel.searchInput',
                                })}
                                className="w-full max-w-[250px]"
                                id="search-input"
                                onClear={() => setSearch('')}
                                onSubmit={handleSearchChange}
                                placeholder={placeholderMessage}
                                small
                                value={searchTerms}
                            />
                            <div className="flex items-end gap-md smMax:flex-col smMax:items-start smMax:gap-sm">
                                {buyNowFilterAvailable && (
                                    <Checkbox.Option
                                        className="mb-2xs"
                                        defaultChecked={buyNow}
                                        name="buyNowCheckbox"
                                        onChange={handleBuyNowChecked}
                                    >
                                        {formatMessage({
                                            id: 'filter_buy_now',
                                        })}
                                    </Checkbox.Option>
                                )}
                                {showFilter && (
                                    <Select
                                        className="min-w-[110px] smMax:w-full"
                                        data-testid="bid-status-select"
                                        isInsideLabel
                                        labelText={`${formatMessage({ id: 'bid_status' })}:`}
                                        onChange={handleFilterChange}
                                        options={mappedFilterOptions}
                                        placeholder={formatMessage({
                                            id: 'select',
                                        })}
                                        small
                                        value={mappedFilterOptions.find((op) => op.value === filter)}
                                    />
                                )}
                                <Select
                                    className="smMax:w-full"
                                    data-testid="view-options-select"
                                    isInsideLabel
                                    labelText={`${formatMessage({ id: 'view' })}:`}
                                    onChange={handlePageSizeChange}
                                    options={viewOptions}
                                    placeholder={formatMessage({
                                        id: 'select',
                                    })}
                                    small
                                    value={viewOptions.find((op) => op.value === pageSize)}
                                />
                                <Select
                                    className="smMax:w-full"
                                    data-testid="sort-options-select"
                                    isInsideLabel
                                    labelText={`${formatMessage({ id: 'sort' })}:`}
                                    onChange={handleSortChange}
                                    options={filteredSortOptions}
                                    placeholder={formatMessage({
                                        id: 'select',
                                    })}
                                    small
                                    value={filteredSortOptions.find((op) => op.value === sortOrder)}
                                />
                            </div>
                        </div>
                    }
                    onChange={noop}
                    pageSizeOptions={viewsToShow}
                    paginationFilter={{
                        page,
                        pageSize,
                        totalRecords: filteredItemCount || totalItemsCount,
                    }}
                    shouldModifyQueryParams
                >
                    {!empty && <div>{children}</div>}
                </Pagination>
            )}
        </div>
    );
};

export default PaginateSortFilter;
