import { AddressModalData, AddressModalTitleKey } from '@/types/address/address.types';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { CompleteAccountModalContext, ModalContext, openRegisterForAuctionModal } from '@/redux/modules/modal';
import { EventContext } from '@/redux/modules/analytics';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getAddressID } from '@/redux/modules/modal.selectors';
import {
    getCatalogIsCatalogOnly,
    getCatalogIsInstantAutopay,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import { InlineButton } from '@liveauctioneers/hammer-ui-core/inlineButton';
import { memo, useCallback, useMemo } from 'react';
import { submitRegisterForCatalog } from '@/redux/modules/registerForCatalog';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useTrackRegisterForAuctionPressedAnalytics } from '@/utils/analytics';
import ShouldLogInContainer from '../ShouldLogIn/ShouldLogInContainer';
import styled from 'styled-components';

export type RegisterLinkProps = {
    catalogId: number;
    className?: string;
    itemId?: number;
    registerToBidMessageId?: string;
};

const RegisterLink = ({
    catalogId,
    className,
    itemId,
    registerToBidMessageId = 'register_for_auction',
}: RegisterLinkProps) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const isCatalogOnly = useAppSelector((state) => getCatalogIsCatalogOnly(state, catalogId));
    const isInstantAutopay = useAppSelector((state) => getCatalogIsInstantAutopay(state, catalogId));
    const addressId = useAppSelector(getAddressID);

    const modalContext = useMemo<ModalContext>(
        () => ({
            catalogId,
            eventContext: EventContext.registerForAuction,
            id: itemId || catalogId,
            itemId,
            type: itemId
                ? CompleteAccountModalContext.RegisterForAuctionItem
                : CompleteAccountModalContext.RegisterForAuction,
        }),
        [itemId, catalogId]
    );

    const { declined, pending, registered, suspended } = useAppSelector((state) =>
        getCatalogRegistration(state, catalogId)
    );

    const trackRegisterForAuctionPressedAnalytics = useTrackRegisterForAuctionPressedAnalytics();

    const registerForCatalogOnly = useCallback(
        (args) => {
            trackRegisterForAuctionPressedAnalytics();
            dispatch(submitRegisterForCatalog(args));
        },
        [dispatch, trackRegisterForAuctionPressedAnalytics]
    );

    const registerForCatalog = useCallback(() => {
        trackRegisterForAuctionPressedAnalytics();

        dispatch(
            openRegisterForAuctionModal({
                catalogId,
                fromBiddingConsole: false,
                isInstantAutopay,
                itemId,
                modalContext,
            })
        );
    }, [catalogId, dispatch, isInstantAutopay, itemId, modalContext, trackRegisterForAuctionPressedAnalytics]);

    let registeredMessage: string;
    if (suspended) {
        registeredMessage = 'account_suspended';
    } else if (declined) {
        registeredMessage = 'registration_declined';
    } else if (isCatalogOnly) {
        registeredMessage = 'request_submitted';
    } else {
        registeredMessage = pending ? 'registration_pending' : 'registration_approved';
    }

    if (registered) {
        return (
            <RegisteredSpan
                className={className}
                data-testid="itemPageRegisteredForAuction"
            >
                <FormattedMessage id={registeredMessage} />
            </RegisteredSpan>
        );
    }

    const addressData: AddressModalData = {
        addressID: addressId,
        titleKey: AddressModalTitleKey.RegisterForAuction,
    };

    if (isCatalogOnly) {
        return (
            <ShouldLogInContainer
                completeAddressData={addressData}
                requireCompleteProfile
                requireLogin
                requireVerifiedAddress
                runFunction={registerForCatalogOnly}
                runFunctionArgument={{ catalogId, itemId }}
            >
                <InlineButton
                    className={className}
                    data-testid="itemPageRegisterForAuction"
                    size="sm"
                >
                    {formatMessage({ id: 'request_online_bidding' })}
                </InlineButton>
            </ShouldLogInContainer>
        );
    }

    return (
        <InlineButton
            className={className}
            data-testid="itemPageRegisterForAuction"
            onClick={registerForCatalog}
            size="sm"
        >
            {formatMessage({ id: registerToBidMessageId })}
        </InlineButton>
    );
};

export default memo<RegisterLinkProps>(RegisterLink);

const RegisteredSpan = styled(BodySecondary)`
    @media print {
        display: none;
    }
`;
