import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { fetchLiveCatalogStatusIfNeeded } from '@/redux/modules/liveCatalogStatus';
import {
    getCatalogIsTimedPlus,
    getCatalogIsTimedPlusBiddingOpen,
    getCatalogSellerId,
    getCatalogShortAddress,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useEffect } from 'react';
import CatalogDate from '@/components/CatalogComponent/components/Details/components/CatalogDate/CatalogDate';
import HorizontalCardButton from './HorizontalCardButton';
import loadable from '@loadable/component';
import RegisterLink from '../../../RegisterLink/RegisterLink';
import StatusMessage from './StatusMessage';
import styled from 'styled-components';

export type HorizontalCardInfoProps = {
    catalogId: number;
    className?: string;
};

/** Establish connection to real-time auction events, only on the client-side */
// TODO: this is temporary, we'll eventually connect to ATG's shared timed bidding service
const LiveAuctionEventsSubscriber = loadable(
    () => import('@/components/LiveEventSubscribers/LiveAuctionEventsSubscriber'),
    {
        ssr: false,
    }
);

const HorizontalCardInfo = ({ catalogId, className }: HorizontalCardInfoProps) => {
    const dispatch = useAppDispatch();
    const location = useAppSelector((state) => getCatalogShortAddress(state, catalogId));
    const sellerId = useAppSelector((state) => getCatalogSellerId(state, catalogId));
    const isTimedPlus = useAppSelector((state) => getCatalogIsTimedPlus(state, catalogId));
    const isTimedPlusBiddingOpen = useAppSelector((state) => getCatalogIsTimedPlusBiddingOpen(state, catalogId));

    useEffect(() => {
        if (isTimedPlus) {
            dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, sellerId));
        }
    }, [catalogId, dispatch, isTimedPlus, sellerId]);

    return (
        <StyledHorizontalCardInfo className={className}>
            {isTimedPlusBiddingOpen && (
                <LiveAuctionEventsSubscriber
                    catalogId={catalogId}
                    limitMessagesFilter="catalogPageConsole"
                />
            )}
            <StyledDateRow
                catalogId={catalogId}
                hideBorders
                hideIcon
            />
            <StyledAddress>{location}</StyledAddress>
            <StyledStatusMessage catalogId={catalogId} />
            <StyledHorizontalCardButton catalogId={catalogId} />
            <StyledRegisterLinkContainer data-testid="sellerRegisterLink">
                <StyledRegisterLink catalogId={catalogId} />
            </StyledRegisterLinkContainer>
        </StyledHorizontalCardInfo>
    );
};

export default HorizontalCardInfo;

const StyledHorizontalCardInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledDateRow = styled(CatalogDate)`
    max-width: 100%;
    margin-bottom: 8px;
`;

const StyledAddress = styled(BodySecondary)`
    && {
        display: block;
        margin-bottom: 8px;
    }
`;

const StyledStatusMessage = styled(StatusMessage)`
    margin-bottom: 8px;
`;

const StyledHorizontalCardButton = styled(HorizontalCardButton)`
    margin: 8px 0 16px;
`;

const StyledRegisterLinkContainer = styled.div`
    margin-bottom: 8px;
`;

const StyledRegisterLink = styled(RegisterLink)`
    margin: 0;
`;
