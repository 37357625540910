import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { CheckmarkIconExtraSmall, CheckmarkIconSmall } from '@liveauctioneers/hammer-ui-core/icons/checkmarkIcon';
import { CompleteAccountModalContext, ModalContext } from '@/redux/modules/modal';
import { EventContext } from '@/redux/modules/analytics';
import {
    getFollowedSellerLoading,
    isSellerFollowed,
    submitFollowSeller,
    submitUnfollowSeller,
} from '@/redux/modules/followSeller';
import { getItemIdFromPath } from '@/utils/urls';
import { getItemModelCatalogId } from '@/redux/modules/item/summary/itemSummary.selectors';
import { HeartIconExtraSmall, HeartIconSmall } from '@liveauctioneers/hammer-ui-core/icons/heartIcon';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCallback, useMemo } from 'react';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { useLocation } from 'react-router';
import { useTrackAuctioneerFollowedAnalytics, useTrackAuctioneerUnfollowedAnalytics } from '@/utils/analytics';
import noop from '@/utils/noop';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';

type Props = {
    className?: string;
    followText?: string;
    followingText?: string;
    longText?: boolean;
    sellerId: number;
    small?: boolean;
};

const FollowSellerButton = ({
    className,
    followingText = 'bidding_console.console.currentLiveItem.auctioneerInfo.buttons.following',
    followText = 'bidding_console.console.currentLiveItem.auctioneerInfo.buttons.follow',
    longText,
    sellerId,
    small,
}: Props) => {
    const followed = useAppSelector((state) => isSellerFollowed(state, sellerId));
    const followedSellerLoading = useAppSelector(getFollowedSellerLoading);
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const itemId = getItemIdFromPath(pathname);
    const itemCatalogId = useAppSelector((state) => getItemModelCatalogId(state, itemId));
    const itemSellerId = useAppSelector((state) => getItemModelCatalogId(state, itemId));

    const trackAuctioneerFollowedAnalytics = useTrackAuctioneerFollowedAnalytics();
    const trackAuctioneerUnfollowedAnalytics = useTrackAuctioneerUnfollowedAnalytics();

    const { follow, following } = useMemo(
        () => ({
            follow: longText
                ? 'bidding_console.console.currentLiveItem.auctioneerInfo.buttons.followAuctioneer'
                : followText,
            following: longText
                ? 'bidding_console.console.currentLiveItem.auctioneerInfo.buttons.followingAuctioneer'
                : followingText,
        }),
        [followText, followingText, longText]
    );

    const { checkedIcon, heartIcon } = useMemo(
        () => ({
            checkedIcon: small ? CheckmarkIconExtraSmall : CheckmarkIconSmall,
            heartIcon: small ? HeartIconExtraSmall : HeartIconSmall,
        }),
        [small]
    );

    const modalContext = useMemo<ModalContext>(
        () => ({
            catalogId: itemCatalogId || 0,
            eventContext: EventContext.followAuctioneer,
            id: itemSellerId || sellerId,
            itemId: itemId || 0,
            sellerId: sellerId || itemSellerId || 0,
            type: CompleteAccountModalContext.FollowAuctioneer,
        }),
        [itemId, itemCatalogId, itemSellerId, sellerId]
    );

    const toggleFollowSeller = useCallback(() => {
        if (followed) {
            dispatch(submitUnfollowSeller(sellerId));
            trackAuctioneerUnfollowedAnalytics();
        } else {
            dispatch(submitFollowSeller(sellerId));
            trackAuctioneerFollowedAnalytics();
        }
    }, [dispatch, followed, sellerId, trackAuctioneerFollowedAnalytics, trackAuctioneerUnfollowedAnalytics]);

    return (
        <ShouldLogInContainer
            modalContext={modalContext}
            requireLogin
            runFunction={toggleFollowSeller}
        >
            <Button
                className={className}
                data-testid="followSellerButton"
                iconLeft={followed ? checkedIcon : heartIcon}
                loading={followedSellerLoading}
                onClick={noop}
                size={small ? 'sm' : 'md'}
                variant={followed ? 'outlined' : 'filled'}
            >
                {formatMessage({ id: followed ? following : follow })}
            </Button>
        </ShouldLogInContainer>
    );
};

export default FollowSellerButton;
