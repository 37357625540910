import { connect } from 'react-redux';
import { getSellerCatalogCounts } from '@/redux/modules/sellerCatalogCounts';
import { getSellerCatalogs } from '@/redux/modules/filteredItems';
import { getSellerPreviewCatalogIds } from '@/redux/modules/sellerPreviewCatalogs';
import { GlobalState } from '@/redux/store';
import CatalogMeta from './CatalogMeta';
import isEqual from 'react-fast-compare';
import React, { Component } from 'react';
import SellerPageUpcomingSection from './SellerPageUpcomingSection';

type OwnProps = {
    baseUrl: string;
    sellerId: number;
    sellerName: string;
};

const mapStateToProps = (state: GlobalState, { sellerId }: OwnProps) => {
    const { upcomingCount: upcomingCatalogCount } = getSellerCatalogCounts(state, sellerId);

    const { catalogIds: upcomingCatalogIds } = getSellerCatalogs(state, sellerId, {
        count: 'all',
        page: 1,
        sort: 'DATE_ASCENDING',
        tab: 'upcoming',
    });

    const previewCatalogIds = getSellerPreviewCatalogIds(state, sellerId) || [];

    return {
        previewCatalogIds,
        sellerId,
        upcomingCatalogCount,
        upcomingCatalogIds,
    };
};

type ReduxProps = {
    previewCatalogIds: number[];
    upcomingCatalogCount: number;
    upcomingCatalogIds: number[];
};

type Props = OwnProps & ReduxProps;

class SellerPageUpcomingSectionContainer extends Component<Props> {
    shouldComponentUpdate = (nextProps) => !isEqual(this.props, nextProps);

    render() {
        const { baseUrl, previewCatalogIds, sellerId, sellerName, upcomingCatalogCount, upcomingCatalogIds } =
            this.props;

        return (
            <>
                {upcomingCatalogIds.map((catalogId) => {
                    return (
                        <CatalogMeta
                            baseUrl={baseUrl}
                            catalogId={catalogId}
                            key={catalogId}
                        />
                    );
                })}
                <SellerPageUpcomingSection
                    name={sellerName}
                    previewCatalogIds={previewCatalogIds}
                    sellerId={sellerId}
                    upcomingCatalogCount={upcomingCatalogCount}
                    upcomingCatalogIds={upcomingCatalogIds}
                />
            </>
        );
    }
}

export default connect(mapStateToProps)(SellerPageUpcomingSectionContainer);
