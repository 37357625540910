import * as React from 'react';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getSellerDescription } from '@/redux/modules/sellerDetail';
import { useAppSelector } from '@/redux/hooks';
import Panel from '@/components/Panel/Panel';
import PanelAccordion from '@/components/Panel/PanelAccordion';
import SellerMap from './SellerMap';
import styled from 'styled-components';

type Props = {
    sellerId: number;
};

const SellerAccordion = ({ sellerId }: Props) => {
    const description = useAppSelector((state) => getSellerDescription(state, sellerId));
    return (
        <PanelAccordion>
            <Panel
                collapsible
                header={<FormattedMessage id="about_auctioneer" />}
            >
                <StyledDescription>{description}</StyledDescription>
            </Panel>
            <Panel
                collapsible
                header={<FormattedMessage id="contact_auctioneer" />}
            >
                <SellerMap sellerId={sellerId} />
            </Panel>
        </PanelAccordion>
    );
};

export default SellerAccordion;

const StyledDescription = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    display: block;
    float: left;
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin-bottom: 20px;
    white-space: pre-wrap;
    margin-top: 19px;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin: 0;
    }
`;
