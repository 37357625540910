import * as React from 'react';
import { BodyPrimary, BodySecondary, Caption, H3, H4 } from '@liveauctioneers/hammer-ui-core/text';
import { CompleteAccountModalContext, openSendSellerConsignmentMessageModal } from '@/redux/modules/modal';
import { EventContext } from '@/redux/modules/analytics';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getCatalogPreview } from '@/redux/modules/sellerPreviewCatalogs';
import { getSellerName } from '@/redux/modules/seller';
import { longFormatDate } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { safelyEscapeText } from '@/utils/safelyRender';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import CatalogDate from '../CatalogDate/CatalogDate';
import FollowSellerButton from '../FollowSellerButton/FollowSellerButton';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import noop from '@/utils/noop';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';
import styled from 'styled-components';
import Tooltip from '@liveauctioneers/caterwaul-components/lib/Tooltip/Tooltip';

type Props = {
    catalogId: number;
    className?: string;
    horizontal?: boolean;
    trackClick: (event: React.SyntheticEvent) => void;
};

const PreviewCatalogCard = ({ catalogId, className = '', horizontal = false, trackClick = noop }: Props) => {
    const dispatch = useAppDispatch();
    const { description, imagePath, saleStartTs, sellerId, title } = useAppSelector((state) =>
        getCatalogPreview(state, catalogId)
    );
    const name = useAppSelector((state) => getSellerName(state, sellerId));

    const showSendSellerConsignmentMessageModal = React.useCallback(() => {
        const messageDetails = {};
        dispatch(openSendSellerConsignmentMessageModal(messageDetails, sellerId, name));
    }, [dispatch, name, sellerId]);

    const startDate = longFormatDate(saleStartTs);

    if (!horizontal) {
        return (
            <StyledCatalogCard>
                <StyledFullImageWrapper>
                    <img
                        alt={`${title}: Auction Preview`}
                        loading="lazy"
                        src={imagePath}
                    />
                </StyledFullImageWrapper>
                <StyledInfoContainer>
                    <StyledTitleRow>{title}</StyledTitleRow>
                    <StyledSellerNameRow>{name}</StyledSellerNameRow>
                    <StyledStartTimeRow color="secondary">
                        <FormattedMessage
                            id="starts"
                            values={{ startDate }}
                        />
                        <FollowSellerButton
                            followingText="following_updates"
                            followText="follow_updates"
                            sellerId={sellerId}
                        />
                    </StyledStartTimeRow>
                </StyledInfoContainer>
            </StyledCatalogCard>
        );
    }

    return (
        <StyledHorizontalCatalogCard
            className={className}
            onClick={trackClick}
        >
            <StyledImageWrapper>
                <StyledImage
                    alt={`${title} preview`}
                    loading="lazy"
                    src={imagePath}
                />
            </StyledImageWrapper>
            <StyledCardBody>
                <StyledTitle>{title}</StyledTitle>
                <BodyPrimary>
                    <StyledDescription>{safelyEscapeText(description)}</StyledDescription>
                </BodyPrimary>
            </StyledCardBody>
            <StyledInfoSection>
                <StyledDateRow color="secondary">
                    <FormattedMessage
                        id="starts"
                        values={{ startDate }}
                    />
                </StyledDateRow>
                <StyledCatalogDate
                    date={saleStartTs}
                    onlyShowCountdown
                />
                <StyledExplorButtonContainer>
                    <StyledFollowSellerButton sellerId={sellerId} />
                    <StyledConsignLinkContainer>
                        <ShouldLogInContainer
                            modalContext={{
                                eventContext: EventContext.consign,
                                id: sellerId,
                                sellerId,
                                type: CompleteAccountModalContext.Default,
                            }}
                            requireCompleteProfile
                            requireLogin
                            runFunction={showSendSellerConsignmentMessageModal}
                        >
                            <Link>
                                <FormattedMessage id="consign_item" />
                            </Link>
                        </ShouldLogInContainer>
                    </StyledConsignLinkContainer>
                    <Tooltip
                        placement="bottom"
                        trigger={
                            <StyledWhyCantI color="secondary">
                                <FormattedMessage id="why_cant_i_view_the_catalog" />
                            </StyledWhyCantI>
                        }
                    >
                        <FormattedMessage id="this_catalog_is_an_auction_preview" />
                    </Tooltip>
                </StyledExplorButtonContainer>
            </StyledInfoSection>
        </StyledHorizontalCatalogCard>
    );
};

export default React.memo<Props>(PreviewCatalogCard);

const StyledCatalogCard = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    width: 100%;
`;

const StyledFullImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 238px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const StyledInfoContainer = styled.div`
    display: block;
    padding: 10px;
`;

const StyledTitleRow = styled(H4)`
    height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
`;

const StyledSellerNameRow = styled.div`
    height: 24px;
    font-family: ${({ theme }) => theme.typography.fontPrimaryStrong};
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const StyledStartTimeRow = styled(Caption)`
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledHorizontalCatalogCard = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    margin: 0;
    padding: 32px 0;

    &:first-child {
        border-top: 1px solid ${({ theme }) => theme.colors.grey400};
    }

    &:last-child {
        border-bottom: none;
    }
`;

const StyledImageWrapper = styled.div`
    height: 207px;
    width: 316px;
    flex: 0 0 316px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 24px;
    border: 1px solid ${({ theme }) => theme.colors.grey400};
`;

const StyledImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const StyledCardBody = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 16px 24px 16px 0;
    flex: 1 1 auto;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletWidth}) {
        max-height: 400px;
    }
`;

const StyledTitle = styled(H3)`
    margin-bottom: 8px;
`;

const StyledDescription = styled.div`
    color: ${({ theme }) => theme.colors.grey100};

    /* I think this works on all browsers */
    && {
        -webkit-line-clamp: 3;
        overflow: hidden;
        white-space: normal;

        /* stylelint-disable value-no-vendor-prefix  */
        /* stylelint-disable property-no-vendor-prefix */
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
`;

const StyledInfoSection = styled.div`
    width: 270px;
    flex: 0 0 270px;
`;

const StyledDateRow = styled(BodySecondary)`
    max-width: 100%;
    display: block;
    margin-bottom: 16px;
`;

const StyledFollowSellerButton = styled(FollowSellerButton)`
    margin-bottom: 16px;
`;

const StyledConsignLinkContainer = styled.div`
    margin-bottom: 16px;
`;

const StyledWhyCantI = styled(BodySecondary)`
    text-decoration: underline;
`;

const StyledCatalogDate = styled(CatalogDate)`
    margin-bottom: 16px;
    display: inline-block;
`;

const StyledExplorButtonContainer = styled.div`
    margin-bottom: 16px;
`;
