import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getInitialWidth } from '@/redux/modules/windowSize';
import { getSearchAuctioneerUrl } from '@/utils/urls';
import { H2 } from '@liveauctioneers/hammer-ui-core/text';
import { memo } from 'react';
import { RouteButton } from '@/components/RouteButton/RouteButton';
import {
    updateSearchSellerItemsSearch,
    updateSearchSellerItemsSearchSellerId,
} from '@/redux/modules/search/search.actions';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import CatalogGrid from '@/components/CatalogCard/CatalogGrid';
import FollowSellerLink from './FollowSellerLink';
import HorizontalCatalogCard from '@/components/CatalogCard/HorizontalCatalogCard';
import isEqual from 'react-fast-compare';
import NoResults from '@/components/Pagination/NoResults';
import PreviewCatalogCard from '@/components/CatalogCard/PreviewCatalogCard';
import styled, { useTheme } from 'styled-components';
import useIsNarrowerThan from '@/hooks/useIsNarrowerThan';

type Props = {
    name: string;
    previewCatalogIds: number[];
    sellerId: number;
    upcomingCatalogCount: number;
    upcomingCatalogIds: number[];
};

const SellerPageUpcomingSection = ({
    name,
    previewCatalogIds,
    sellerId,
    upcomingCatalogCount,
    upcomingCatalogIds,
}: Props) => {
    const dispatch = useAppDispatch();

    const hasUpcomingCatalogs = upcomingCatalogCount > 0;
    const hasPreviewCatalogs = previewCatalogIds.length > 0;
    const searchUrl = getSearchAuctioneerUrl(sellerId);
    const { formatMessage } = useIntl();
    const {
        breakpoints: { tabletNarrowWidthNum },
    } = useTheme();

    const ssrWidth = useAppSelector(getInitialWidth);
    const mobile = useIsNarrowerThan(tabletNarrowWidthNum + 1, ssrWidth);

    return (
        <StyledSellerPageUpcomingSectionContainer id="seller-page-upcoming-section">
            <StyledSellerPageUpcomingSection>
                <StyledSectionTitle>
                    <FormattedMessage
                        id="upcoming_auctions_from"
                        values={{ name }}
                    />
                </StyledSectionTitle>
                {hasUpcomingCatalogs ? (
                    <StyledCatalogGrid
                        CardComponent={mobile ? undefined : HorizontalCatalogCard}
                        catalogIds={upcomingCatalogIds}
                        horizontal={!mobile}
                        lazyLoadImages
                        testid="upcomingAuctionGrid"
                    />
                ) : !hasPreviewCatalogs ? (
                    <NoResults>
                        <FollowSellerLink sellerId={sellerId} />
                    </NoResults>
                ) : null}
                {hasPreviewCatalogs && (
                    <CatalogGrid
                        CardComponent={PreviewCatalogCard}
                        catalogIds={previewCatalogIds}
                        horizontal={!mobile}
                        lazyLoadImages
                        previewSellerId={sellerId}
                    />
                )}
                {hasUpcomingCatalogs && (
                    <StyledSearchCTA>
                        <RouteButton
                            onClick={() => {
                                dispatch(updateSearchSellerItemsSearchSellerId(sellerId));
                                dispatch(updateSearchSellerItemsSearch(true));
                            }}
                            rel="nofollow"
                            to={searchUrl}
                        >
                            {formatMessage({ id: 'search_upcoming_items' })}
                        </RouteButton>
                    </StyledSearchCTA>
                )}
            </StyledSellerPageUpcomingSection>
        </StyledSellerPageUpcomingSectionContainer>
    );
};

export default memo<Props>(SellerPageUpcomingSection, isEqual);

const StyledSellerPageUpcomingSectionContainer = styled.div`
    margin: 24px auto 0;
    width: 100%;
`;

const StyledSellerPageUpcomingSection = styled.div`
    align-self: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    width: calc(100% - 40px);
`;

const StyledSectionTitle = styled(H2)`
    margin: 24px 0 32px;
    text-align: left;
`;

const StyledCatalogGrid = styled(CatalogGrid)`
    display: flex;
    flex-flow: row wrap;
    place-content: stretch flex-start;
    align-items: stretch;
    padding: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        flex: 1 0 auto;
        margin: -10px -10px 0;
        max-width: calc(100% + 20px);
        width: calc(100% + 20px);
    }
`;

const StyledSearchCTA = styled.div`
    margin: 24px auto 0;
    text-align: center;

    a {
        width: 264px;
    }
`;
