import * as React from 'react';
import {
    getCatalogIsTimedPlus,
    getCatalogIsTimedPlusBiddingOpen,
    getCatalogTitle,
    getShouldShowLive,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getCatalogUrl } from '@/utils/urls';
import { getLiveCatalogStatus } from '@/redux/modules/console';
import { RouteButton } from '@/components/RouteButton/RouteButton';
import { useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import BidConsoleOpener from '@/components/BidConsoleOpener/BidConsoleOpener';
import styled from 'styled-components';

type Props = {
    catalogId: number;
    className?: string;
};

const HorizontalCardButton = ({ catalogId, className }: Props) => {
    const { formatMessage } = useIntl();
    const title = useAppSelector((state) => getCatalogTitle(state, catalogId));
    const isTimedPlus = useAppSelector((state) => getCatalogIsTimedPlus(state, catalogId));
    const timedPlusBiddingOpen = useAppSelector((state) => getCatalogIsTimedPlusBiddingOpen(state, catalogId));
    const catalogUrl = getCatalogUrl(catalogId, title);

    // Live catalog data selectors
    const showLive = useAppSelector((state) => getShouldShowLive(state, catalogId));
    const liveStatus = useAppSelector((state) => getLiveCatalogStatus(state, catalogId));
    const live = (showLive || liveStatus === 'live') && !isTimedPlus;

    return (
        <div className={className}>
            {timedPlusBiddingOpen ? (
                <StyledHorizontalCardButton
                    style="critical"
                    to={catalogUrl}
                    variant="filled"
                >
                    {formatMessage({ id: 'catalog_card.horizontal.bidNow' })}
                </StyledHorizontalCardButton>
            ) : live ? (
                <BidConsoleOpener catalogId={catalogId}>
                    <StyledHorizontalCardButton
                        style="critical"
                        to={catalogUrl}
                        variant="filled"
                    >
                        {formatMessage({
                            id: 'catalog_card.horizontal.enterLiveAuction',
                        })}
                    </StyledHorizontalCardButton>
                </BidConsoleOpener>
            ) : (
                <StyledHorizontalCardButton
                    data-testid="exploreButtonText"
                    to={catalogUrl}
                >
                    {formatMessage({ id: 'explore' })}
                </StyledHorizontalCardButton>
            )}
        </div>
    );
};

export default HorizontalCardButton;

const StyledHorizontalCardButton = styled(RouteButton)`
    width: 280px;
`;
