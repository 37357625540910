import * as React from 'react';
import { DotsThreeOutlineVertical } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { FormattedMessage, FormattedNumber } from '@liveauctioneers/hammer-ui-core/intl';
import { getAuctioneerLogoUrl, imagePlaceholder } from '@/utils/urls';
import { getIsWhiteLabel } from '@/redux/modules/whiteLabel';
import { getSellerDescription } from '@/redux/modules/sellerDetail';
import { getSellerFollowerCountById } from '@/redux/modules/sellerFollowerCount';
import { getSellerLogoId, getSellerName } from '@/redux/modules/seller';
import {
    getSellerRatingAllowPublicReviews,
    getSellerRatingOverall,
    getSellerRatingTotalReviews,
} from '@/redux/modules/sellerRatings';
import { H5 } from '@liveauctioneers/hammer-ui-core/text';
import { isTopRatedHouse } from '@/redux/modules/topRatedSellers';
import { isUserExcludedHouse } from '@/redux/modules/search/exclusions/searchExclusions.selectors';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { openExcludeHouseModal, openSendSellerConsignmentMessageModal } from '@/redux/modules/modal';
import { safelyRender } from '@/utils/safelyRender';
import { setSearchExclusion } from '@/redux/modules/search/exclusions/searchExclusions.actions';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import FollowSellerButton from '@/components/FollowSellerButton/FollowSellerButton';
import PageTitle from '@/components/PageTitle/PageTitle';
import PopoverMenu, { Option } from '@liveauctioneers/caterwaul-components/lib/PopoverMenu/PopoverMenu';
import RatingDisplaySmall from '@/components/RatingDisplaySmall/RatingDisplaySmall';
import SellerAccordion from './SellerAccordion';
import SellerMap from './SellerMap';
import styled from 'styled-components';
import TextCollapse from '@liveauctioneers/caterwaul-components/lib/TextCollapse/TextCollapse';
import TopBadge from '@/components/Badges/TopBadge';
import useIsNarrowerThan from '@/hooks/useIsNarrowerThan';

const MIN_NUMBER_OF_TEXT_COLLAPSE_PIXELS = 105;

type Props = {
    scrollToReviews: (event: any) => void;
    sellerId: number;
    showConsignmentModal: boolean;
};

const SellerComponent = ({ scrollToReviews, sellerId, showConsignmentModal }: Props) => {
    const whiteLabel = useAppSelector(getIsWhiteLabel);
    const logoId = useAppSelector((state) => getSellerLogoId(state, sellerId));
    const name = useAppSelector((state) => getSellerName(state, sellerId));
    const description = useAppSelector((state) => getSellerDescription(state, sellerId));
    const sellerIsTopRated = useAppSelector((state) => isTopRatedHouse(state, sellerId));
    const sellerFollowerCount = useAppSelector((state) => getSellerFollowerCountById(state, sellerId));
    const overall = useAppSelector((state) => getSellerRatingOverall(state, sellerId));
    const totalReviews = useAppSelector((state) => getSellerRatingTotalReviews(state, sellerId));
    const allowPublicReviews = useAppSelector((state) => getSellerRatingAllowPublicReviews(state, sellerId));

    const dispatch = useAppDispatch();
    const isMobile = useIsNarrowerThan(761);
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const isExcludedHouse = useAppSelector((state) => isUserExcludedHouse(state, sellerId));
    const [showConsignment, setShowConsignment] = React.useState(showConsignmentModal);

    const safeDescription = safelyRender({ html: description, wrapper: 'div' });

    if (showConsignment) {
        setShowConsignment(false);
        dispatch(openSendSellerConsignmentMessageModal({}, sellerId, name));
    }

    const displayFollowerCount = Boolean(sellerFollowerCount) && sellerFollowerCount >= 10;
    const ratingDisplay = totalReviews >= 5 ? overall : 0;

    const showConfirmExcludeModal = React.useCallback(() => {
        dispatch(openExcludeHouseModal(sellerId));
    }, [dispatch, sellerId]);

    const undoExcludeHouse = React.useCallback(() => {
        dispatch(
            setSearchExclusion({
                exclude: false,
                facetId: 'auctionHouse',
                optionId: sellerId,
            })
        );
    }, [dispatch, sellerId]);

    const showSubMenuTrigger = userLoggedIn && !whiteLabel;

    if (!name || !sellerId) {
        return null;
    }

    return (
        <StyledSeller>
            <StyledSellerInfo>
                <StyledTopInfo>
                    {!whiteLabel && (
                        <StyledLogoImageContainer>
                            <StyledImageLogo
                                alt={name}
                                src={logoId ? getAuctioneerLogoUrl(logoId, 140) : imagePlaceholder}
                            />
                        </StyledLogoImageContainer>
                    )}
                    <StyledSellerTopTextInfo>
                        <StyledPageTitle
                            title={
                                <>
                                    <StyledTitleName data-testid="sellerNameTitle">{name}</StyledTitleName>
                                    {sellerIsTopRated && (
                                        <StyledTitleTop>
                                            <TopBadge />
                                        </StyledTitleTop>
                                    )}
                                </>
                            }
                        />
                        {Boolean(allowPublicReviews) && (
                            <RatingDisplaySmall
                                onClick={scrollToReviews}
                                rating={ratingDisplay}
                                reviewCount={totalReviews}
                            />
                        )}
                        <StyledFollowRow>
                            <StyledFollowSellerButton
                                sellerId={sellerId}
                                small
                            />
                            {displayFollowerCount && (
                                <StyledH5 as="span">
                                    <FormattedMessage
                                        id="followers"
                                        values={{
                                            count: (
                                                <FormattedNumber
                                                    key="followers_count"
                                                    value={sellerFollowerCount}
                                                />
                                            ),
                                        }}
                                    />
                                </StyledH5>
                            )}
                            {showSubMenuTrigger && (
                                <PopoverWrapper>
                                    <PopoverMenu
                                        placement={isMobile ? 'bottom' : 'left'}
                                        trigger={
                                            <TriggerWrapper>
                                                <DotsThreeOutlineVertical
                                                    size="sm"
                                                    style="fill"
                                                />
                                            </TriggerWrapper>
                                        }
                                    >
                                        <Option onClick={isExcludedHouse ? undoExcludeHouse : showConfirmExcludeModal}>
                                            <FormattedMessage
                                                id={isExcludedHouse ? 'seller_include_house' : 'seller_exclude_house'}
                                            />
                                        </Option>
                                    </PopoverMenu>
                                </PopoverWrapper>
                            )}
                        </StyledFollowRow>
                    </StyledSellerTopTextInfo>
                </StyledTopInfo>
                {isMobile ? (
                    <SellerAccordion sellerId={sellerId} />
                ) : (
                    <>
                        {Boolean(safeDescription) && (
                            <StyledAboutTextCollapse
                                content={safeDescription}
                                minHeightPx={MIN_NUMBER_OF_TEXT_COLLAPSE_PIXELS}
                            />
                        )}
                    </>
                )}
            </StyledSellerInfo>
            {!isMobile && <SellerMap sellerId={sellerId} />}
        </StyledSeller>
    );
};

export default SellerComponent;

const StyledSeller = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    max-width: 1200px;
    align-self: center;
    width: calc(100% - 40px);

    @media (max-width: 1200px) {
        margin-bottom: 21px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-bottom: 30px;
    }
`;

const PopoverWrapper = styled.span`
    padding-left: 5px;
`;

const TriggerWrapper = styled.span`
    display: flex;
    justify-content: center;
    width: 20px;
    cursor: pointer;
`;

const StyledH5 = styled(H5)`
    display: inline-block;
`;

const StyledAboutTextCollapse = styled(TextCollapse)`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    display: block;
    float: left;
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin-bottom: 20px;
    white-space: pre-wrap;
    margin-top: 19px;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin: 0;
    }
`;

const StyledLogoImageContainer = styled.div`
    width: 140px;
    height: 140px;
    margin-right: 25px;
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    position: relative;
    flex: 0 0 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        width: 64px;
        height: 64px;
        flex: 0 0 64px;
        align-self: center;
        margin: 0;
    }
`;

const StyledImageLogo = styled.img`
    max-width: 132px;
    max-height: 132px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        max-width: 60px;
        max-height: 60px;
    }
`;

const StyledSellerInfo = styled.div`
    width: calc(100% - 524px);
    display: flex;
    flex-direction: column;
    margin-right: 40px;

    @media (max-width: 1200px) {
        width: calc(100% - 212px);
        margin-right: 84px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    }
`;

const StyledTopInfo = styled.div`
    display: flex;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        flex-direction: column;
        text-align: center;
    }
`;

const StyledSellerTopTextInfo = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: flex-start;
    flex: 1 1;
    position: relative;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-bottom: 24px;
        margin-top: 10px !important;
        align-items: center;
    }
`;

const StyledPageTitle = styled(PageTitle)`
    && {
        margin: 0;
        display: flex;

        @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
        }
    }
`;

const StyledTitleName = styled.span`
    display: inline-block;
    margin: 0 10px 10px 0;
    vertical-align: middle;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin: 0;
    }
`;

const StyledTitleTop = styled.span`
    margin-left: 6px;
    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        align-self: center;
    }
`;

const StyledFollowRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-top: 16px;
`;

const StyledFollowSellerButton = styled(FollowSellerButton)`
    margin-right: 16px;
`;
