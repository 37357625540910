import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { CaretDown, CaretUp } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { Collapse } from 'react-collapse';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

type Props = {
    children: React.ReactNode;
};

const PanelAccordion = (props: Props) => {
    const { children } = props;
    const [selectedChild, selectChild] = React.useState(-1);

    const ExtractPanels = React.useCallback(
        (node, index) => {
            if (node && node.props && node.props.collapsible) {
                const { className, header } = node.props;
                return (
                    <div
                        className={className}
                        key={index}
                    >
                        <StyledPanelHeader
                            onClick={() => (selectedChild !== index ? selectChild(index) : selectChild(-1))}
                        >
                            <BodySecondary color="secondary">
                                {React.isValidElement(header)
                                    ? React.cloneElement(node.props.header)
                                    : node.props.header}
                            </BodySecondary>
                            {selectedChild !== index ? (
                                <CaretDown
                                    size="xs"
                                    style="fill"
                                />
                            ) : (
                                <CaretUp
                                    size="xs"
                                    style="fill"
                                />
                            )}
                        </StyledPanelHeader>
                        <div>
                            <Collapse isOpened={selectedChild === index}>
                                <StyledContainer>{node.props.children}</StyledContainer>
                            </Collapse>
                        </div>
                    </div>
                );
            } else {
                return node;
            }
        },
        [selectedChild]
    );

    // @ts-ignore
    return children && children.map ? children.map(ExtractPanels) : children;
};

export default React.memo<Props>(PanelAccordion, isEqual);

const StyledPanelHeader = styled.div`
    padding: 22px 0 19px;
    line-height: 18px;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.grey400};
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledContainer = styled.div`
    padding: 16px;
    overflow-y: hidden;
`;
