import { BidderAddress } from '@/types/BidderAddresses';

export enum AddressModalTitleKey {
    AddNewAddress = 'addresses.addNewAddress',
    EditAddress = 'addresses.editAddress',
    PlaceBid = 'addresses.placeBid',
    RegisterForAuction = 'addresses.registerForAuction',
}

export type AddressModalData = {
    address?: BidderAddress;
    addressID: number;
    titleKey?: AddressModalTitleKey;
};
