import {
    fetchBidderStatusesByIdIfNeeded,
    fetchHelpfulCountsByIdIfNeeded,
    getUiReviewStatus,
    helpfulCountByIdSelector,
    statusesByIdSelector,
} from '@/redux/modules/reviewStatus';
import {
    fetchSellerCommentsByIdIfNeeded,
    fetchSellerLatestTopReviewsByIdIfNeeded,
    fetchSellerPercentilesByIdIfNeeded,
    fetchSellerRatingSummaryByIdIfNeeded,
    getActiveSeller,
    getCommentsById,
    getSellerPercentilesById,
    getSellerRatingById,
    loadSellerRatingsById,
    SellerRatingsMeta,
} from '@/redux/modules/sellerRatings';

import * as React from 'react';
import { fetchSellersIfNeeded, getSeller } from '@/redux/modules/seller';
import { getBaseUrl } from '@/redux/modules/config';
import { HouseData } from '@/types/HousePercentile';
import { parseQueryParams } from '@/utils/queryParams';
import { Rating } from '@/types/Rating';
import { ReviewComment } from '@/types/Review';
import { Seller } from '@/types/Seller';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import RatingsPage from './RatingsPage';

type RatingsContainerProps = {
    baseUrl: string;
    dismissReviewsModal: () => void;
    fetchBidderStatusesByIdIfNeeded: (reviewIds: number[]) => void;
    fetchHelpfulCountsByIdIfNeeded: (reviewIds: number[]) => void;
    fetchSellerCommentsByIdIfNeeded: (sellerId: number) => void;
    fetchSellerLatestTopReviewsByIdIfNeeded: (sellerId: number) => void;
    fetchSellerPercentilesByIdIfNeeded: (sellerId: number) => void;
    fetchSellerRatingSummaryByIdIfNeeded: (sellerId: number) => void;
    fetchSellersIfNeeded: (sellerIds: number[], loadingId?: string) => void;
    helpfulCounts: Record<number, number>;
    houseRating: Rating;
    id: number;
    loadSellerRatingsById: (params: SellerRatingsMeta) => void;
    modal: boolean;
    navigate: ReturnType<typeof useNavigate>;
    percentiles: HouseData;
    queryParams: {};
    reviewComments: ReviewComment[];
    seller: Seller;
    sellerId?: number;
    statuses: Record<number, string[]>;
    submitted: boolean;
};

class RatingsContainer extends React.Component<RatingsContainerProps> {
    static defaultProps = {
        houseRating: {
            accuracy: 0,
            allowPublicReviews: 0,
            filteredReviewCount: 0,
            fiveStarReviews: 0,
            fourStarReviews: 0,
            houseId: 0,
            oneStarReviews: 0,
            overall: 0,
            payment: 0,
            responsiveness: 0,
            reviews: [],
            shipping: 0,
            threeStarReviews: 0,
            totalReviews: 0,
            tsUpdated: 0,
            twoStarReviews: 0,
        },
    };
    componentDidMount() {
        const { houseRating, id } = this.props;
        this.props.fetchSellersIfNeeded([id]);
        this.props.fetchSellerRatingSummaryByIdIfNeeded(id);
        this.props.fetchSellerLatestTopReviewsByIdIfNeeded(id);
        this.props.fetchSellerPercentilesByIdIfNeeded(id);
        this.props.fetchSellerCommentsByIdIfNeeded(id);
        if (Boolean(houseRating?.reviews)) {
            const ids = houseRating.reviews.map((r) => r.reviewId);
            this.props.fetchBidderStatusesByIdIfNeeded(ids);
            this.props.fetchHelpfulCountsByIdIfNeeded(ids);
        }
    }

    shouldComponentUpdate = (nextProps: RatingsContainerProps) => !isEqual(this.props, nextProps);

    componentDidUpdate(prevProps) {
        if (
            !isEqual(this.props.houseRating, prevProps.houseRating) &&
            Boolean(this.props.houseRating?.reviews?.length)
        ) {
            const ids = this.props.houseRating.reviews.map((r) => r.reviewId);
            this.props.fetchBidderStatusesByIdIfNeeded(ids);
            this.props.fetchHelpfulCountsByIdIfNeeded(ids);
        }
    }

    render() {
        const {
            baseUrl,
            dismissReviewsModal,
            fetchBidderStatusesByIdIfNeeded,
            fetchHelpfulCountsByIdIfNeeded,
            helpfulCounts,
            houseRating,
            id,
            loadSellerRatingsById,
            modal,
            navigate,
            percentiles,
            queryParams,
            reviewComments,
            seller,
            statuses,
            submitted,
        } = this.props;

        return (
            <RatingsPage
                baseUrl={baseUrl}
                dismissReviewsModal={dismissReviewsModal}
                fetchBidderStatusesByIdIfNeeded={fetchBidderStatusesByIdIfNeeded}
                fetchHelpfulCountsByIdIfNeeded={fetchHelpfulCountsByIdIfNeeded}
                fullPage={false}
                helpfulCounts={helpfulCounts}
                houseRating={houseRating}
                loadSellerRatingsById={loadSellerRatingsById}
                modal={modal}
                navigate={navigate}
                percentiles={percentiles}
                queryParams={queryParams}
                reviewComments={reviewComments}
                seller={seller}
                sellerId={id}
                statuses={statuses}
                submitted={submitted}
            />
        );
    }
}

type RatingsContainerWrapperProps = {
    dismissReviewsModal?: () => void;
    modal?: boolean;
    params?: any;
    sellerId?: number | undefined;
};

/**
 * This function allowed removing react-redux compose and redux-first-history from the class above without rewriting it's componentDidUpdate craziness
 */
const RatingsContainerWrapper = ({ dismissReviewsModal, modal, params, sellerId }: RatingsContainerWrapperProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const activeSeller = useAppSelector(getActiveSeller);
    const id = sellerId || params?.sellerId || activeSeller;
    const baseUrl = useAppSelector(getBaseUrl);
    const reviewComments = useAppSelector((state) => getCommentsById(state, id)) as ReviewComment[];
    const percentiles = useAppSelector((state) => getSellerPercentilesById(state, id)) as HouseData;
    const houseRating: Rating = useAppSelector((state) => getSellerRatingById(state, id));
    const seller = useAppSelector((state) => getSeller(state, id));
    const statuses = useAppSelector(statusesByIdSelector);
    const helpfulCounts = useAppSelector(helpfulCountByIdSelector);
    const queryParams = location ? parseQueryParams(location?.search) : {};
    const { submitted } = useAppSelector(getUiReviewStatus);

    return (
        <RatingsContainer
            baseUrl={baseUrl}
            dismissReviewsModal={dismissReviewsModal}
            fetchBidderStatusesByIdIfNeeded={(reviewIds) => dispatch(fetchBidderStatusesByIdIfNeeded(reviewIds))}
            fetchHelpfulCountsByIdIfNeeded={(reviewIds) => dispatch(fetchHelpfulCountsByIdIfNeeded(reviewIds))}
            fetchSellerCommentsByIdIfNeeded={(sellerId) => dispatch(fetchSellerCommentsByIdIfNeeded(sellerId))}
            fetchSellerLatestTopReviewsByIdIfNeeded={(sellerId) =>
                dispatch(fetchSellerLatestTopReviewsByIdIfNeeded(sellerId))
            }
            fetchSellerPercentilesByIdIfNeeded={(sellerId) => dispatch(fetchSellerPercentilesByIdIfNeeded(sellerId))}
            fetchSellerRatingSummaryByIdIfNeeded={(sellerId) =>
                dispatch(fetchSellerRatingSummaryByIdIfNeeded(sellerId))
            }
            fetchSellersIfNeeded={(sellerIds) => dispatch(fetchSellersIfNeeded(sellerIds))}
            helpfulCounts={helpfulCounts}
            houseRating={houseRating}
            id={id}
            loadSellerRatingsById={(params) => dispatch(loadSellerRatingsById(params))}
            modal={modal}
            navigate={navigate}
            percentiles={percentiles}
            queryParams={queryParams}
            reviewComments={reviewComments}
            seller={seller}
            sellerId={sellerId}
            statuses={statuses}
            submitted={submitted}
        />
    );
};

export default RatingsContainerWrapper;
