import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { useIsMobileLayout } from '@/hooks/useIsMobileLayout';
import Tab from '@liveauctioneers/caterwaul-components/lib/Tab/Tab';

type Props = {
    activeTab: string;
    hasRecordResults: boolean;
    hideReviews: boolean;
    id?: string;
    nonSticky?: boolean;
    scrollToSection: Function;
};

const SellerPageTabLinks = ({ activeTab, hasRecordResults, hideReviews, id, nonSticky, scrollToSection }: Props) => {
    const mobile = useIsMobileLayout();

    const scrollToUpcoming = () => {
        scrollToSection('seller-page-upcoming-section');
    };

    const scrollToPast = () => {
        scrollToSection('seller-page-past-section');
    };

    const scrollToReviews = () => {
        scrollToSection('seller-page-reviews-section');
    };

    const scrollToResults = () => {
        scrollToSection('seller-page-results-section');
    };

    const actionLabel = !nonSticky && Boolean(mobile) ? 'sellerPageTabLinks.auctions' : 'sellerPageTabLinks.upcoming';

    return (
        <div
            className={cn(
                'flex w-[calc(100%-40px)] max-w-[1200px] flex-row self-center overflow-x-auto overflow-y-hidden whitespace-nowrap border-b border-surface-200',
                {
                    'border-b-0 [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden':
                        !nonSticky,
                }
            )}
            id={id || ''}
        >
            <Tab
                label={<FormattedMessage id={actionLabel} />}
                name="upcoming"
                onChange={scrollToUpcoming}
                selectedTabName={activeTab}
                testid="sellerUpcomingTabLink"
            >
                {null}
            </Tab>
            {!hideReviews && (
                <Tab
                    label={<FormattedMessage id="sellerPageTabLinks.reviews" />}
                    name="reviews"
                    onChange={scrollToReviews}
                    selectedTabName={activeTab}
                    testid="sellerReviewsTabLink"
                >
                    {null}
                </Tab>
            )}
            <Tab
                label={<FormattedMessage id="sellerPageTabLinks.past" />}
                name="past"
                onChange={scrollToPast}
                selectedTabName={activeTab}
                testid="sellerPastTabLink"
            >
                {null}
            </Tab>
            {Boolean(hasRecordResults) && (
                <Tab
                    label={<FormattedMessage id="sellerPageTabLinks.results" />}
                    name="results"
                    onChange={scrollToResults}
                    selectedTabName={activeTab}
                    testid="sellerResultsTabLink"
                >
                    {null}
                </Tab>
            )}
        </div>
    );
};

export default SellerPageTabLinks;
