import * as React from 'react';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getSellerName } from '@/redux/modules/seller';
import { InlineButton } from '@liveauctioneers/hammer-ui-core/inlineButton';
import { isSellerFollowed, submitFollowSeller, submitUnfollowSeller } from '@/redux/modules/followSeller';
import { LabelStrong } from '@liveauctioneers/hammer-ui-core/text';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import styled from 'styled-components';

type Props = {
    className?: string;
    sellerId: number;
};

const FollowSellerLink = ({ className, sellerId }: Props) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const name = useAppSelector((state) => getSellerName(state, sellerId));
    const followed = useAppSelector((state) => isSellerFollowed(state, sellerId));

    const toggleFollowSeller = React.useCallback(() => {
        if (followed) {
            dispatch(submitUnfollowSeller(sellerId));
        } else {
            dispatch(submitFollowSeller(sellerId));
        }
    }, [dispatch, followed, sellerId]);

    return (
        <StyledFollowSellerLink className={className}>
            <FormattedMessage
                id={followed ? 'no_upcoming_auctions_message_followed' : 'no_upcoming_auctions_message_not_followed'}
                values={
                    followed
                        ? { name: <strong>{name}</strong> }
                        : {
                              cta: (
                                  <InlineButton
                                      onClick={toggleFollowSeller}
                                      size="sm"
                                  >
                                      {formatMessage({ id: 'follow_this' })}
                                  </InlineButton>
                              ),
                              name: <LabelStrong color="secondary">{name}</LabelStrong>,
                          }
                }
            />
        </StyledFollowSellerLink>
    );
};

export default FollowSellerLink;

const StyledFollowSellerLink = styled.span`
    display: inline-block;
`;
