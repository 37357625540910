import * as React from 'react';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import styled from 'styled-components';

type Props = {
    clear: (event: any) => void;
    itemCount: number;
    search: string;
};

const SearchStatus = ({ clear, itemCount = 0, search = '' }: Props) => {
    if (!search) {
        return null;
    }

    return (
        <StyledSearchStatusContainer>
            <StyledSearchMatch>
                <StyledResultsFor>
                    <FormattedMessage
                        id="results_for"
                        values={{ count: `${itemCount}` }}
                    />
                    <StyledSearchText> {search}</StyledSearchText>
                    <StyledClearText onClick={clear}>
                        <FormattedMessage id="clear" />
                    </StyledClearText>
                </StyledResultsFor>
            </StyledSearchMatch>
            {!itemCount && (
                <StyledNoMatch>
                    <FormattedMessage id="try_another_search" />
                </StyledNoMatch>
            )}
        </StyledSearchStatusContainer>
    );
};

export default SearchStatus;

const StyledSearchStatusContainer = styled.div`
    display: block;
    padding: 0 0 10px;
`;

const StyledSearchMatch = styled.div`
    line-height: 30px;
`;

const StyledResultsFor = styled.span`
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    font-size: 14px;
    margin-right: 5px;
    text-transform: uppercase;
`;

const StyledSearchText = styled.span`
    font-size: 20px;
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.typography.fontSecondary};
    margin-right: 5px;
    text-transform: none;
`;

const StyledClearText = styled.span`
    color: ${({ theme }) => theme.colors.blue200};
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    text-transform: initial;
`;

const StyledNoMatch = styled.div`
    color: ${({ theme }) => theme.colors.grey100};
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.blueLight};
    margin: 20px 0;
    padding: 134px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        padding: 47px;
    }
`;
