import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { ClockCountdown } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getCatalogEndTime,
    getCatalogIsCatalogOnly,
    getCatalogIsDone,
    getCatalogIsTimedPlus,
    getCatalogIsTimedPlusBiddingOpen,
    getCatalogIsTimedPlusClosing,
    getCatalogStartTime,
    getCatalogStatus,
    getCatalogTimedPlusBiddingEndsFromTs,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { longFormatDate } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { shouldShowLive } from '@/utils/live';
import { useAppSelector } from '@/redux/hooks';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled, { css } from 'styled-components';
import Tooltip from '@liveauctioneers/caterwaul-components/lib/Tooltip/Tooltip';

type Props = {
    catalogId: number;
    className?: string;
    forceIsLive?: boolean;
    forceOneDate?: boolean;
    hideBorders?: boolean;
    hideIcon?: boolean;
};

const CatalogDate = ({ catalogId, className, forceIsLive, forceOneDate, hideBorders, hideIcon }: Props) => {
    const isCatalogDone = useAppSelector((state) => getCatalogIsDone(state, catalogId));
    const isCatalogTimedPlus = useAppSelector((state) => getCatalogIsTimedPlus(state, catalogId));
    const biddingEndsFrom =
        useAppSelector((state) => getCatalogTimedPlusBiddingEndsFromTs(state, catalogId)) || Date.now() / 1000;
    const biddingOpens = useAppSelector((state) => getCatalogStartTime(state, catalogId)) || Date.now() / 1000;
    const catalogStatus = useAppSelector((state) => getCatalogStatus(state, catalogId));
    const isCatalogOnly = useAppSelector((state) => getCatalogIsCatalogOnly(state, catalogId));
    const saleEndTs = useAppSelector((state) => getCatalogEndTime(state, catalogId));
    const timedPlusBiddingOpen = useAppSelector((state) => getCatalogIsTimedPlusBiddingOpen(state, catalogId));
    const timedPlusClosing = useAppSelector((state) => getCatalogIsTimedPlusClosing(state, catalogId));
    const isLive = forceIsLive || shouldShowLive(catalogStatus, isCatalogOnly, biddingOpens);
    const showTwoDates = !forceOneDate && !timedPlusBiddingOpen;

    const [biddingOpensFromDate, setBiddingOpensFromDate] = React.useState('');
    const [saleEndDate, setSaleEndDate] = React.useState('');
    const [biddingEndsFromDate, setBiddingEndsFromDate] = React.useState('');

    React.useEffect(() => {
        setBiddingOpensFromDate(longFormatDate(biddingOpens));
        setSaleEndDate(longFormatDate(saleEndTs));
        setBiddingEndsFromDate(longFormatDate(biddingEndsFrom));
    }, [biddingEndsFrom, biddingOpens, saleEndTs]);

    return (
        <StyledCatalogDate
            $hideBorders={hideBorders}
            $twoDates={showTwoDates}
            className={className}
            data-testid="catalog-date"
        >
            {isCatalogDone ? (
                <>
                    {isCatalogTimedPlus && !hideIcon && (
                        <StyledIconContainer>
                            <Tooltip
                                placement="bottom"
                                trigger={
                                    <ClockCountdown
                                        color="primary"
                                        data-testid="timed-plus-icon"
                                        size="sm"
                                    />
                                }
                            >
                                <FormattedMessage
                                    id="catalogPage.catalogDate.timedPlusMessage"
                                    values={{
                                        link: (
                                            <Link
                                                key="catalog-date-timed-plus-message-link"
                                                rel="noopener"
                                                target="_blank"
                                                to="https://support.liveauctioneers.com/hc/en-gb/articles/24574071591313-How-do-Timed-auctions-work"
                                            >
                                                <FormattedMessage id="catalogPage.catalogDate.learnMore" />
                                            </Link>
                                        ),
                                    }}
                                />
                            </Tooltip>
                        </StyledIconContainer>
                    )}
                    <StyledSecondaryDone color="secondary">
                        <FormattedMessage id="catalogPage.catalogDate.done" />
                        {Boolean(saleEndDate) && (
                            <StyledInlineTime
                                color="secondary"
                                data-testid="end-time"
                            >
                                {saleEndDate}
                            </StyledInlineTime>
                        )}
                    </StyledSecondaryDone>
                </>
            ) : isCatalogTimedPlus ? (
                <>
                    {!hideIcon && (
                        <StyledIconContainer>
                            <Tooltip
                                placement="bottom"
                                trigger={
                                    <ClockCountdown
                                        color="primary"
                                        data-testid="timed-plus-icon"
                                        size="sm"
                                    />
                                }
                            >
                                <FormattedMessage
                                    id="catalogPage.catalogDate.timedPlusMessage"
                                    values={{
                                        link: (
                                            <Link
                                                key="catalog-date-timed-plus-message-link"
                                                rel="noopener"
                                                target="_blank"
                                                to="https://support.liveauctioneers.com/hc/en-gb/articles/24574071591313-How-do-Timed-auctions-work"
                                            >
                                                <FormattedMessage id="catalogPage.catalogDate.learnMore" />
                                            </Link>
                                        ),
                                    }}
                                />
                            </Tooltip>
                        </StyledIconContainer>
                    )}
                    <StyledDateContainer>
                        {showTwoDates && (
                            <StyledSecondaryTimeFirst color="secondary">
                                <FormattedMessage
                                    id="catalogPage.catalogDate.startsOn"
                                    values={{
                                        date: biddingOpensFromDate,
                                    }}
                                />
                            </StyledSecondaryTimeFirst>
                        )}
                        {timedPlusClosing ? (
                            <StyledSecondaryTime color="secondary">
                                <FormattedMessage id="catalogPage.catalogDate.endingNow" />
                            </StyledSecondaryTime>
                        ) : (
                            <StyledSecondaryTime color="secondary">
                                <FormattedMessage
                                    id="catalogPage.catalogDate.endsFrom"
                                    values={{
                                        date: biddingEndsFromDate,
                                    }}
                                />
                            </StyledSecondaryTime>
                        )}
                    </StyledDateContainer>
                </>
            ) : isLive ? (
                <StyledSecondaryTime
                    color="secondary"
                    data-testid="timed-or-live-date"
                >
                    <FormattedMessage
                        id="catalogPage.catalogDate.started"
                        values={{
                            date: biddingOpensFromDate,
                        }}
                    />
                </StyledSecondaryTime>
            ) : (
                <StyledSecondaryTime
                    color="secondary"
                    data-testid="timed-or-live-date"
                >
                    <FormattedMessage
                        id="catalogPage.catalogDate.startsOn"
                        values={{
                            date: biddingOpensFromDate,
                        }}
                    />
                </StyledSecondaryTime>
            )}
        </StyledCatalogDate>
    );
};

export default CatalogDate;

const StyledCatalogDate = styled.div<{
    $hideBorders: boolean;
    $twoDates: boolean;
}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: ${({ $twoDates }) => ($twoDates ? 'flex-start' : 'center')};
    padding: 0 16px 0 0;
    border-right: 1px solid ${({ theme }) => theme.colors.grey400};

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        align-items: center;
        border-right: none;
        margin-bottom: 8px;
    }

    ${({ $hideBorders }) =>
        $hideBorders &&
        css`
            border: none;
        `}
`;

const StyledIconContainer = styled.div`
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > div {
        height: 20px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-right: 4px;
    }
`;

const StyledDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        padding: 0 0 0 8px;
        border-left: 1px solid ${({ theme }) => theme.colors.grey400};
        margin-left: 4px;
    }
`;

const StyledSecondaryDone = styled(BodySecondary)`
    text-transform: uppercase;
`;

const StyledSecondaryTime = styled(BodySecondary)`
    display: block;
`;

const StyledInlineTime = styled(BodySecondary)`
    display: inline-block;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid ${({ theme }) => theme.colors.grey400};

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-left: 8px;
        padding-left: 8px;
    }
`;

const StyledSecondaryTimeFirst = styled(BodySecondary)`
    display: block;
    margin-bottom: 4px;
`;
