import * as React from 'react';
import { getSellerEncodedLocation } from '@/redux/modules/seller';
import { useAppSelector } from '@/redux/hooks';
import styled from 'styled-components';

const googleBaseUrl = 'https://www.liveauctioneers.com/maps/api/staticmap';

type Props = {
    googleApiKey: string;
    sellerId: number;
};

const GoogleMapSmall = ({ googleApiKey, sellerId }: Props) => {
    const encodedLocation = useAppSelector((state) => getSellerEncodedLocation(state, sellerId));
    const mapParams = [
        'size=238x170',
        `center=${encodedLocation}`,
        'zoom=13',
        'scale=2',
        'maptype=roadmap',
        'format=png',
        'visual_refresh=true',
        `markers=size:mid%7Clabel:%7C${encodedLocation}`,
        'style=feature:poi%7Cvisibility:off',
        `key=${googleApiKey}`,
    ];
    const mapTileSrc = `${googleBaseUrl}?${mapParams.join('&')}`;
    return (
        <a
            href={`https://www.google.com/maps/place/${encodedLocation}`}
            rel="noopener noreferrer"
            target="_blank"
        >
            <StyledMap
                alt="map"
                height={170}
                src={mapTileSrc}
                width={238}
            />
        </a>
    );
};

export default React.memo<Props>(GoogleMapSmall);

const StyledMap = styled.img`
    object-fit: contain;
    max-height: 170px;
    width: 238px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum + 1}px) {
        width: 194px;
    }

    @media (min-width: 1201px) {
        width: 238px;
    }
`;
