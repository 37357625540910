import * as React from 'react';
import { cleanSpace, getCanonicalUrl, noZeroOrOne } from './helpers/metaHelpers';
import { escapeQuotes } from '@/utils/escapeQuotes';
import { getAuctioneerLogoUrl, getSellerUrl, logoUrl } from '@/utils/urls';
import { getBaseUrl } from '@/redux/modules/config';
import { getSeller } from '@/redux/modules/seller';
import { getSellerCatalogCounts } from '@/redux/modules/sellerCatalogCounts';
import { getSellerDescription, getSellerPhoneNumber } from '@/redux/modules/sellerDetail';
import { getSellerRatingById } from '@/redux/modules/sellerRatings';
import { parseQueryParams } from '@/utils/queryParams';
import { safelyEscapeText } from '@/utils/safelyRender';
import { useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { useLocation } from 'react-router-dom';
import PageHeaderMeta from '@liveauctioneers/caterwaul-components/lib/PageHeaderMeta/PageHeaderMeta';
import TEST_HOUSES from '@/utils/testHouses';
import useAddNotificationCountToTitle from '@/hooks/useAddNotificationCountToTitle';

type Props = {
    sellerId: number;
};

const getReviewData = ({ bidderFirstName, overall, review: reviewText, reviewHeadline, reviewSubmitted }) => {
    const description = `${reviewHeadline} ${reviewText}`;

    const safeDescription = safelyEscapeText(escapeQuotes(description));
    return `{
        "@context": "https://schema.org",
        "@type": "Review",
        "datePublished": "${reviewSubmitted}",
        "description": "${safeDescription}",
        "author": {
            "@type": "Thing",
            "name": "${bidderFirstName}"
        },
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "${overall.toFixed(1)}"
        }
    }`;
};

const SellerPageHeaderMeta = ({ sellerId }: Props) => {
    const baseUrl = useAppSelector(getBaseUrl);
    const { search } = useLocation();
    const queryParams = parseQueryParams(search);
    const { formatMessage } = useIntl();
    const sellerDescription = useAppSelector((state) => getSellerDescription(state, sellerId));
    const { doneCount: pastCatalogsCount, upcomingCount: upcomingCatalogCount } = useAppSelector((state) =>
        getSellerCatalogCounts(state, sellerId)
    );
    const phoneNumber = useAppSelector((state) => getSellerPhoneNumber(state, sellerId));
    const sellerRating = useAppSelector((state) => getSellerRatingById(state, sellerId));

    const { address, address2, city, country, logoId, name, stateCode, zip } = useAppSelector((state) =>
        getSeller(state, sellerId)
    );
    const displayAddress = address2 ? `${address} ${address2}` : `${address}`;
    const safeDisplayAddress = safelyEscapeText(displayAddress);

    const { page } = queryParams;
    const stateText = stateCode ? `, ${stateCode}` : '';

    let title = cleanSpace(
        safelyEscapeText(`${name}${stateText} - Upcoming Auctions & ${noZeroOrOne(pastCatalogsCount)} Past Catalogs`)
    );

    if (page > 1) {
        title += formatMessage({ id: 'page_number_and_title' }, { page });
    }

    title = useAddNotificationCountToTitle(title);

    if (!name) {
        return null;
    }
    const defaultDescription = cleanSpace(
        `Browse ${noZeroOrOne(
            upcomingCatalogCount
        )} upcoming auctions from ${name}. Also research ${name}'s past ${noZeroOrOne(
            pastCatalogsCount
        )} catalogs and their final hammer price.`
    );
    let description = sellerDescription ? `${sellerDescription.substring(0, 200)}...` : defaultDescription;
    const canonical = getCanonicalUrl(baseUrl, getSellerUrl(sellerId, name), page);
    const metaImage = logoId ? getAuctioneerLogoUrl(logoId) : logoUrl;
    const metaUrl = `${baseUrl}${getSellerUrl(sellerId, name)}`;

    const reviewsData = Boolean(sellerRating?.reviews?.length)
        ? sellerRating.reviews
              .map((review) => {
                  return getReviewData(review);
              })
              .join(',\n')
        : '';

    const ratingString = !sellerRating.allowPublicReviews
        ? ''
        : `
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${sellerRating.overall}",
            "reviewCount": "${sellerRating.totalReviews}"
          },
          "review": [${reviewsData}],
        `;

    description = escapeQuotes(safelyEscapeText(description));

    const dontIndex = TEST_HOUSES.includes(sellerId);

    return (
        <PageHeaderMeta
            canonical={canonical}
            description={description}
            imageUrl={metaImage}
            title={title}
        >
            {dontIndex && (
                <meta
                    content="noindex, nofollow"
                    name="robots"
                />
            )}
            <script type="application/ld+json">
                {`{
                        "@context": "https://schema.org",
                        "@type": "LocalBusiness",
                        "address": {
                          "@type": "PostalAddress",
                          "addressCountry": "${country}",
                          "addressLocality": "${city}",
                          "addressRegion": "${stateCode}",
                          "streetAddress": "${escapeQuotes(safeDisplayAddress)}",
                          "postalCode": "${zip}"
                        },
                        "description": "${description}",
                        "name": "${name}",
                        "image": "${metaImage}",
                        "location": {
                            "@type": "PostalAddress",
                            "addressCountry": "${country}",
                            "addressLocality": "${city}",
                            "addressRegion": "${stateCode}",
                            "streetAddress": "${escapeQuotes(safeDisplayAddress)}",
                            "postalCode": "${zip}"
                          },${ratingString}
                        "logo": "${metaImage}",
                        "telephone": "${phoneNumber}",
                        "url": "${metaUrl}"
                    }`}
            </script>
            <script type="application/ld+json">
                {`{
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [{
                                "@type": "ListItem",
                                "item": {
                                    "@id":"${baseUrl}",
                                    "name":"Home"
                                },
                                "position": 1
                            },
                            {
                                "@type": "ListItem",
                                "item": {
                                    "@id":"${baseUrl}/auctioneer/directory/",
                                    "name":"Auction House Directory"
                                },
                                "position": 2
                        },
                        {
                            "@type": "ListItem",
                            "item": {
                                "@id": "${canonical}",
                                "name":"${title}"
                            },
                            "position": 3
                        }]
                    }`}
            </script>
        </PageHeaderMeta>
    );
};

export default SellerPageHeaderMeta;
