import * as React from 'react';
import { BodyPrimary, H3 } from '@liveauctioneers/hammer-ui-core/text';
import {
    getCatalogCleanDescription,
    getCatalogIsSEOExcluded,
    getCatalogIsTimedPlus,
    getCatalogIsTimedPlusClosing,
    getCatalogTitle,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getCatalogUrl } from '@/utils/urls';
import { SubtleMonochromeLink, SubtleWeakLink } from '@liveauctioneers/hammer-ui-core/link';
import { useAppSelector } from '@/redux/hooks';
import CoverLotsComponent from '@/components/CoverLots/CoverLotsComponent';
import HorizontalCardInfo from './components/HorizontalCardInfo/HorizontalCardInfo';
import styled from 'styled-components';
import TimedPlusBadge from '@liveauctioneers/caterwaul-components/lib/Badges/TimedPlusBadge';

export type HorizontalCatalogCardProps = {
    catalogId: number;
    className?: string;
    lazyLoadImages?: boolean;
    testid?: string;
    trackClick: (event: React.SyntheticEvent) => void;
};

const HorizontalCatalogCard = ({
    catalogId,
    className = 'horizontal-catalog-card',
    lazyLoadImages,
    testid = 'horizontal-catalog-card',
    trackClick,
}: HorizontalCatalogCardProps) => {
    // Standard catalog data selectors
    const isSEOExcluded = useAppSelector((state) => getCatalogIsSEOExcluded(state, catalogId));
    const title = useAppSelector((state) => getCatalogTitle(state, catalogId));
    const description = useAppSelector((state) => getCatalogCleanDescription(state, catalogId));
    const isTimedPlus = useAppSelector((state) => getCatalogIsTimedPlus(state, catalogId));
    const isTimedPlusClosing = useAppSelector((state) => getCatalogIsTimedPlusClosing(state, catalogId));
    const catalogUrl = getCatalogUrl(catalogId, title);
    const rel = isSEOExcluded ? 'nofollow' : '';

    return (
        <StyledCatalogCard
            className={className}
            data-testid={testid}
            onClick={trackClick}
        >
            <StyledCoverlotWrapper>
                {isTimedPlus && isTimedPlusClosing && <StyledTimedPlusBadge closing={isTimedPlusClosing} />}
                <StyledCoverLots
                    catalogId={catalogId}
                    isSEOExcluded={isSEOExcluded}
                    lazyLoadImages={lazyLoadImages}
                    title={title}
                />
            </StyledCoverlotWrapper>
            <StyledCardBody>
                <StyledTitle>
                    <SubtleMonochromeLink
                        className="font-secondary text-xl no-underline hover:text-link-hover hover:no-underline"
                        href={catalogUrl}
                        rel={rel}
                    >
                        {title}
                    </SubtleMonochromeLink>
                </StyledTitle>
                <BodyPrimary>
                    <SubtleWeakLink
                        className="line-clamp-3 overflow-hidden whitespace-normal hover:text-link-hover hover:no-underline"
                        href={catalogUrl}
                        rel={rel}
                        size="md"
                    >
                        {description}
                    </SubtleWeakLink>
                </BodyPrimary>
            </StyledCardBody>
            <StyledHorizontalCardInfo catalogId={catalogId} />
        </StyledCatalogCard>
    );
};

export default React.memo<HorizontalCatalogCardProps>(HorizontalCatalogCard);

const StyledCatalogCard = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    border-bottom: solid 1px ${({ theme }) => theme.colors.grey400};
    margin: 0;
    padding: 32px 0;

    &:last-child {
        border-bottom: none;
    }
`;

const StyledCoverLots = styled(CoverLotsComponent)`
    display: block;
`;

const coverLotWidth = 316;
const cardInfoWidth = 282;
const StyledCoverlotWrapper = styled.div`
    width: ${coverLotWidth}px;
    flex: 0 0 ${coverLotWidth}px;
    position: relative;
    margin-right: 24px;
`;

const StyledCardBody = styled.div`
    text-align: left;
    max-width: calc(100% - ${coverLotWidth + cardInfoWidth}px - 48px);
    display: flex;
    flex-direction: column;
    margin: 16px 24px 16px 0;
    flex: 1 1 auto;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletWidth}) {
        max-height: 400px;
    }
`;

const StyledTitle = styled(H3)`
    margin-bottom: 8px;
`;

const StyledHorizontalCardInfo = styled(HorizontalCardInfo)`
    width: ${cardInfoWidth}px;
    flex: 0 0 ${cardInfoWidth}px;
`;

const StyledTimedPlusBadge = styled(TimedPlusBadge)`
    position: absolute;
    top: 8px;
    left: 8px;
`;
