import * as React from 'react';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import CatalogGrid from '@/components/CatalogCard/CatalogGrid';
import isEqual from 'react-fast-compare';
import PaginateSortFilter from '@/components/Pagination/PaginateSortFilter';
import styled from 'styled-components';

type Props = {
    catalogIds: number[];
    changePaginationPageSize: Function;
    filteredCount: number;
    page: number;
    pageSize: number | 'all';
    searchTerms: string;
    sellerName: string;
    sortOptions: any;
    sortOrder: string;
    totalItemsCount: number;
    viewsToShow: any[];
};

const SellerPagePastSection = (props: Props) => {
    const {
        catalogIds,
        changePaginationPageSize,
        filteredCount,
        page,
        pageSize,
        searchTerms,
        sellerName,
        sortOptions,
        sortOrder,
        totalItemsCount,
        viewsToShow,
    } = props;

    const { formatMessage } = useIntl();

    return totalItemsCount > 0 ? (
        <StyledSellerPagePastSection
            data-testid="sellerPagePastSection"
            id="seller-page-past-section"
        >
            <StyledSectionTitle id="seller-page-past-header">
                <FormattedMessage
                    id="past_auctions_from"
                    values={{ name: sellerName }}
                />
            </StyledSectionTitle>
            <StyledPaginateSortFilter
                changePaginationPageSize={changePaginationPageSize}
                filteredItemCount={filteredCount}
                page={page}
                pageSize={pageSize}
                searchTerms={searchTerms}
                sortOptions={sortOptions}
                sortOrder={sortOrder}
                totalItemsCount={totalItemsCount}
                type={formatMessage({ id: 'past_auctions' })}
                viewsToShow={viewsToShow}
            >
                <StyledCatalogGrid
                    catalogIds={catalogIds}
                    lazyLoadImages
                />
            </StyledPaginateSortFilter>
        </StyledSellerPagePastSection>
    ) : null;
};

export default React.memo<Props>(SellerPagePastSection, isEqual);

const StyledSellerPagePastSection = styled.div`
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 12px;
    max-width: 1200px;
    align-self: center;
    width: calc(100% - 40px);
    flex-direction: column;
    padding-top: 60px;
`;

const StyledSectionTitle = styled.h2`
    margin: 20px 0 33px;
    text-align: left;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin: 20px 0 15px;
    }

    span {
        font-family: ${({ theme }) => theme.typography.fontSecondary};
    }
`;

const StyledCatalogGrid = styled(CatalogGrid)`
    flex: 1 0 auto;
    margin: -10px -10px 0;
    width: calc(100% + 20px);
    max-width: calc(100% + 20px);

    @media (max-width: 374px) {
        margin: 0;
        width: 100%;
    }
`;

const StyledPaginateSortFilter = styled(PaginateSortFilter)`
    width: 100%;
`;
