import ms from 'ms';

type TimeDurations = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
};

/**
 * Get time durations to display "starts in..." information
 * @param timestamp the epoch time IN SECONDS
 * @returns TimeDurations object with days, hours, minutes, and seconds
 */
export function getTimeDurations(timestamp: number): TimeDurations {
    // in milliseconds so that we can use ms function
    const timeUntilClose = timestamp * 1000 - Date.now();
    const totalDays = Math.floor(timeUntilClose / ms('24h'));
    const totalDaysInMilliseconds = totalDays * ms('24h');

    const totalHours = Math.floor((timeUntilClose - totalDaysInMilliseconds) / ms('1h'));
    const totalHoursInMilliseconds = totalHours * ms('1h');

    const totalMinutes = Math.floor((timeUntilClose - totalDaysInMilliseconds - totalHoursInMilliseconds) / ms('1m'));
    const totalMinutesInMilliseconds = totalMinutes * ms('1m');

    const totalSeconds = Math.floor(
        (timeUntilClose - totalDaysInMilliseconds - totalHoursInMilliseconds - totalMinutesInMilliseconds) / ms('1s')
    );

    return {
        days: totalDays,
        hours: totalHours,
        minutes: totalMinutes,
        seconds: totalSeconds,
    };
}
