import { getBaseUrl } from '@/redux/modules/config';
import { getIsWhiteLabel, getWhiteLabelSellerId } from '@/redux/modules/whiteLabel';
import { getNotFoundUrl, sellerIdFromPath } from '@/utils/urls';
import { getSellerLogoId, getSellerName } from '@/redux/modules/seller';
import { getSellerRatingById } from '@/redux/modules/sellerRatings';
import { getSellerRecordResultsIds } from '@/redux/modules/sellerRecordResults';
import { parseQueryParams } from '@/utils/queryParams';
import { recordAnalyticsForSellerPageView } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { waitToDispatch } from '@/utils/waitToDispatch/waitToDispatch';
import isEqual from 'react-fast-compare';
import React, { Component } from 'react';
import SellerPage from './SellerPage';

type SellerPageContainerProps = {
    baseUrl: string;
    hasRecordResults: boolean;
    navigate: ReturnType<typeof useNavigate>;
    queryParams: any;
    sellerId: number;
    sellerLogoId: string;
    sellerName: string;
    sellerRating: any;
    whiteLabel: boolean;
};

class SellerPageContainer extends Component<SellerPageContainerProps> {
    componentDidMount() {
        const { sellerId } = this.props;

        if (!sellerId) {
            return this.props.navigate(getNotFoundUrl());
        }
    }

    shouldComponentUpdate = (nextProps) => !isEqual(this.props, nextProps);

    render() {
        const { baseUrl, hasRecordResults, queryParams, sellerId, sellerLogoId, sellerName, sellerRating, whiteLabel } =
            this.props;

        return (
            <SellerPage
                baseUrl={baseUrl}
                hasRecordResults={hasRecordResults}
                queryParams={queryParams}
                sellerId={sellerId}
                sellerLogoId={sellerLogoId}
                sellerName={sellerName}
                sellerRating={sellerRating}
                whiteLabel={whiteLabel}
            />
        );
    }
}

type SellerPageParams = {
    sellerId: string;
};

/**
 * This function allowed removing react-redux connect and redux-first-history from the class above without rewriting its componentDidUpdate craziness
 */
const SellerPageContainerWrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams<SellerPageParams>();

    const queryParams = parseQueryParams(location.search);
    const whiteLabelSellerId = useAppSelector(getWhiteLabelSellerId);

    const whiteLabel = useAppSelector(getIsWhiteLabel);
    const sellerId = whiteLabel ? whiteLabelSellerId : sellerIdFromPath(params.sellerId);
    const baseUrl = useAppSelector(getBaseUrl);
    const sellerLogoId = useAppSelector((state) => getSellerLogoId(state, sellerId));
    const sellerName = useAppSelector((state) => getSellerName(state, sellerId));
    const sellerRating = useAppSelector((state) => getSellerRatingById(state, sellerId));
    const recordResults = useAppSelector((state) => getSellerRecordResultsIds(state, sellerId));
    const hasRecordResults = recordResults.length > 0;

    React.useEffect(() => {
        waitToDispatch(recordAnalyticsForSellerPageView, dispatch, {
            location,
            sellerId,
        });
    }, [dispatch, location, sellerId]);

    return (
        <SellerPageContainer
            baseUrl={baseUrl}
            hasRecordResults={hasRecordResults}
            navigate={navigate}
            queryParams={queryParams}
            sellerId={sellerId}
            sellerLogoId={sellerLogoId}
            sellerName={sellerName}
            sellerRating={sellerRating}
            whiteLabel={whiteLabel}
        />
    );
};

export default SellerPageContainerWrapper;
