import { useTheme } from 'styled-components';
import useIsWiderThan from './useIsWiderThan';

/**
 * useIsMobileLayout
 * helper to check if client window dimensions are <= 768 px wide
 * return boolean
 */
export const useIsMobileLayout = () => {
    const {
        breakpoints: { tabletNarrowWidthNum },
    } = useTheme();
    const isMobile = !useIsWiderThan(tabletNarrowWidthNum);

    return isMobile;
};
