import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getCatalogIsTimedPlus,
    getCatalogIsTimedPlusBiddingOpen,
    getCatalogIsTimedPlusClosing,
    getCatalogLotsListed,
    getCatalogStartTime,
    getCatalogTimedPlusEstimatedCloseTime,
    getShouldShowLive,
    getTimedPlusCatalogClosedLotCount,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getLiveCatalogStatus } from '@/redux/modules/console';
import { getTimeDurations } from '@/components/CatalogCard/components/HorizontalCardInfo/utils/getTimeDurations';
import { useAppSelector } from '@/redux/hooks';
import { useEffect, useState } from 'react';
import CatalogDate from '../../../CatalogDate/CatalogDate';
import ms from 'ms';
import styled from 'styled-components';

export type StatusMessageProps = {
    catalogId: number;
    className?: string;
};

const StatusMessage = ({ catalogId, className }: StatusMessageProps) => {
    const saleStartTime = useAppSelector((state) => getCatalogStartTime(state, catalogId));
    const timedPlusEstimatedEndTime = useAppSelector((state) =>
        getCatalogTimedPlusEstimatedCloseTime(state, catalogId)
    );
    const isTimedPlus = useAppSelector((state) => getCatalogIsTimedPlus(state, catalogId));
    const isTimedPlusBiddingOpen = useAppSelector((state) => getCatalogIsTimedPlusBiddingOpen(state, catalogId));
    const isTimedPlusBiddingClosing = useAppSelector((state) => getCatalogIsTimedPlusClosing(state, catalogId));
    const totalLotsCount = useAppSelector((state) => getCatalogLotsListed(state, catalogId)) || 100;
    // Live catalog data selectors
    const showLive = useAppSelector((state) => getShouldShowLive(state, catalogId));
    const liveStatus = useAppSelector((state) => getLiveCatalogStatus(state, catalogId));
    const closedLotsCount = useAppSelector((state) => getTimedPlusCatalogClosedLotCount(state, catalogId));
    const live = showLive || liveStatus === 'live';

    const [duration, setDuration] = useState(getTimeDurations(timedPlusEstimatedEndTime));
    const { days, hours, minutes, seconds } = duration;

    useEffect(() => {
        let timeToWait = ms('1s');
        if (days >= 1) {
            timeToWait = ms('1h');
        } else if (hours >= 1) {
            timeToWait = ms('1m');
        }
        const timeout = setTimeout(() => {
            setDuration(getTimeDurations(timedPlusEstimatedEndTime));
        }, timeToWait);
        return () => clearTimeout(timeout);
    }, [days, duration, hours, timedPlusEstimatedEndTime]);

    if (isTimedPlusBiddingClosing) {
        return (
            <StyledLiveStatus
                className={className}
                color="critical"
            >
                <FormattedMessage
                    id="catalog_card.horizontal.lotsClosed"
                    values={{
                        closed: closedLotsCount,
                        total: totalLotsCount,
                    }}
                />
            </StyledLiveStatus>
        );
    } else if (live && !isTimedPlus) {
        return (
            <StyledLiveStatus
                className={className}
                color="critical"
            >
                <FormattedMessage id="catalog_card.horizontal.liveNow" />
            </StyledLiveStatus>
        );
    } else if (isTimedPlus && !isTimedPlusBiddingOpen) {
        return null;
    } else if (!isTimedPlus) {
        return (
            <StyledCatalogDate
                className={className}
                date={saleStartTime}
                onlyShowCountdown
            />
        );
    }

    // Timed+ countdown
    if (days >= 1) {
        return (
            <StyledLiveStatus
                className={className}
                color="critical"
                role="button"
            >
                <FormattedMessage
                    id="itemBidding.endsInDays"
                    values={{ days, hours }}
                />
            </StyledLiveStatus>
        );
    }

    if (hours >= 1) {
        return (
            <StyledLiveStatus
                className={className}
                color="critical"
                role="button"
            >
                <FormattedMessage
                    id="itemBidding.endsInTime"
                    values={{ hours, minutes }}
                />
            </StyledLiveStatus>
        );
    }

    if (minutes >= 1) {
        return (
            <StyledLiveStatus
                className={className}
                color="critical"
                role="button"
            >
                <FormattedMessage
                    id="itemBidding.endsInTimeMinutes"
                    values={{ minutes, seconds }}
                />
            </StyledLiveStatus>
        );
    }

    return (
        <StyledLiveStatus
            className={className}
            color="critical"
            role="button"
        >
            <FormattedMessage
                id="itemBidding.endsInTimeSeconds"
                values={{ seconds }}
            />
        </StyledLiveStatus>
    );
};

export default StatusMessage;

const StyledCatalogDate = styled(CatalogDate)`
    display: inline-block;
`;

const StyledLiveStatus = styled(BodySecondary)`
    display: inline-block;
`;
