import * as React from 'react';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { getSearchAuctioneerSoldUrl } from '@/utils/urls';
import { getSellerName } from '@/redux/modules/seller';
import { getSellerRecordResultsIds } from '@/redux/modules/sellerRecordResults';
import { openSendSellerMessageModal } from '@/redux/modules/modal';
import { RouteButton } from '@/components/RouteButton/RouteButton';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import ItemShelf from '@/components/ItemShelf/ItemShelf';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';
import styled from 'styled-components';

type Props = {
    className?: string;
    sellerId: number;
};

const SellerPageRecordResultsSection = ({ className, sellerId }: Props) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const itemIds = useAppSelector((state) => getSellerRecordResultsIds(state, sellerId));
    const sellerName = useAppSelector((state) => getSellerName(state, sellerId));
    const soldUrl = getSearchAuctioneerSoldUrl(sellerId);

    const showSendSellerMessageModal = React.useCallback(
        ({ subject, title }: { subject?: string; title?: string } = {}) => {
            const messageDetails = {};
            subject = subject || formatMessage({ id: 'general_question' });
            dispatch(openSendSellerMessageModal(messageDetails, sellerId, sellerName, subject, title));
        },
        [dispatch, formatMessage, sellerId, sellerName]
    );

    if (!itemIds.length) {
        return null;
    }

    return (
        <StyledSellerPageRecordResultsContainer
            className={className}
            id="seller-page-results-section"
        >
            <StyledSellerPageRecordResults>
                <ItemShelf
                    identifier="sellerRecordResults"
                    itemIds={itemIds}
                    seeAllUrl={soldUrl}
                    testid="sellerRecordResults"
                    title={formatMessage({ id: 'record_results_for' }, { name: sellerName })}
                />
                <StyledSellerPageRecordResultsCTA>
                    <ShouldLogInContainer
                        requireCompleteProfile
                        requireLogin
                        runFunction={showSendSellerMessageModal}
                        runFunctionArgument={{
                            subject: 'Consignment Inquiry',
                            title: 'Item Consignment',
                        }}
                    >
                        <Button>{formatMessage({ id: 'consign_item' })}</Button>
                    </ShouldLogInContainer>
                    <RouteButton
                        to={soldUrl}
                        variant="outlined"
                    >
                        {formatMessage({ id: 'see_all_price_results' })}
                    </RouteButton>
                </StyledSellerPageRecordResultsCTA>
            </StyledSellerPageRecordResults>
        </StyledSellerPageRecordResultsContainer>
    );
};

export default React.memo<Props>(SellerPageRecordResultsSection);

const StyledSellerPageRecordResultsContainer = styled.div`
    margin: 0 auto 120px;
    max-width: ${({ theme }) => theme.breakpoints.pageWidth};
    width: 100%;
    position: relative;
    padding-top: 60px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-bottom: 50px;
    }
`;

const StyledSellerPageRecordResults = styled.div`
    display: flex;
    margin: 0;
    flex-direction: column;
`;

const StyledSellerPageRecordResultsCTA = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: center;

    button {
        margin: 0 16px 0;
    }

    @media (max-width: 460px) {
        min-height: 110px;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
`;
