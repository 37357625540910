import * as React from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const StickyHeader = ({ children, className }: Props) => (
    <StyledStickyHeaderDiv className={className}>
        <StyledInnerContainerDiv>{children}</StyledInnerContainerDiv>
        <StyledFadeDiv />
    </StyledStickyHeaderDiv>
);

export default React.memo<Props>(StickyHeader, isEqual);

const StyledStickyHeaderDiv = styled.div`
    height: 52px;
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.shadow200};
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledInnerContainerDiv = styled.div`
    display: flex;
    height: 70px;
    align-items: center;
    max-width: 1200px;
    width: calc(100% - 40px);
    margin: 0 20px;
    white-space: nowrap;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        display: none;
    }

    > * {
        vertical-align: center;
    }
`;

const StyledFadeDiv = styled.div`
    background: linear-gradient(
        to left,
        ${({ theme }) => theme.colors.white},
        ${({ theme }) => theme.colors.shadowOverlay50}
    );
    position: absolute;
    right: 0;
    top: 0;
    height: 48px;
    width: 40px;
`;
